import { useRef, useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { SplitText } from "gsap/SplitText";
import { useSplitText } from "../composables/useSplitText";

gsap.registerPlugin(SplitText);

const TitleText = styled.h1`
  color: white;
  font-family: Fraunces;
  font-weight: 100;
  font-size: 58px;
  margin-bottom: 0;
  margin: 0;

  .reveal-child {
    display: block;
  }

  @media screen and (max-width: 768px) {
    font-size: 38px;
  }
`;

export const GradientText = styled.span`
  display: inline-block;
  background: -webkit-linear-gradient(left, #d43d5d, #de8a33);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Title = ({ language, text, children }) => {
  const TitleRef = useRef(null);

  useEffect(() => {
    const splitText = async () => {
      const tl = gsap.timeline();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const mySplitText = await useSplitText(TitleRef.current);

      tl.from(mySplitText.splitText.lines, {
        duration: 0.8,
        opacity: 0,
        y: 60,
        stagger: 0.1,
      });
    };

    splitText();
  }, []);

  return <TitleText ref={TitleRef}>{children}</TitleText>;
};

export default Title;
