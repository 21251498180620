import { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { ReactComponent as ExtraSmall } from "../assets/galaxy_rings/xs.svg";
import { ReactComponent as Small } from "../assets/galaxy_rings/s.svg";
import { ReactComponent as Medium } from "../assets/galaxy_rings/m.svg";
import { ReactComponent as Large } from "../assets/galaxy_rings/l.svg";
import { ReactComponent as ExtraLarge } from "../assets/galaxy_rings/xl.svg";
import Planet from "../components/Planet";
import PopupWrapper from "./PopupWrapper";
import StoryPopup from "./StoryPopup";
import UploadPopup from "./UploadPopup";
import { useSearchParams } from "react-router-dom";
import copy from "../data/GalaxyCopy";
import { dbStories as Stories } from "../data/Stories";
import { useMediaQuery } from "react-responsive";
import { get } from "../modules/Fetch.js";

const RingsContainer = styled.div`
  position: absolute;
  width: 500px;
  height: 500px;
  top: 40%;
  right: 15%;
  transform-origin: center;

  svg {
    opacity: 0.5;
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    display: ${({ enlarged }) => (enlarged ? "none" : "block")};
  }
`;

const RingAndPlanetContainer = styled.div`
  position: absolute;
  right: 0;
  transform: translate(24%, -21%);
  pointer-events: none;
`;

const RandomNumber = (min = 0, max = 1) =>
  Math.floor(Math.random() * max) + min;

let galaxyRings;

const PlanetSize = {
  min: 30,
  max: 60,
};

const PlanetSpeed = {
  min: 20,
  max: 200,
};

const Galaxy = ({ language, enlarged }) => {
  const ExtraSmallRingRef = useRef(null);
  const SmallRingRef = useRef(null);
  const MediumRingRef = useRef(null);
  const LargeRingRef = useRef(null);
  const ExtraLargeRingRef = useRef(null);
  const [Paths, setPaths] = useState({
    xs: "",
    s: "",
    m: "",
    l: "",
    xl: "",
  });
  const ComponentIsMounted = useRef(false);
  const [currentStory, setCurrentStory] = useState(null);
  const RingsContainerRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const [stories, setStories] = useState([]);
  const [galaxyRings, setGalaxyRings] = useState([]);

  const { popup } = params;

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const showPopup = (type, storyId) => {
    searchParams.set("popup", type);
    searchParams.set("storyId", storyId);
    const thisStory = stories.find((story) => story.id === storyId);
    setCurrentStory(thisStory);
    setSearchParams(searchParams);
  };

  const clearPopup = () => {
    // searchParams.set("popup", "");
    // searchParams.set("story", "");
    searchParams.delete("popup");
    searchParams.delete("storyId");

    if (isMobile) {
      searchParams.delete("enlarged");
    }

    setSearchParams(searchParams);
  };

  if (!ComponentIsMounted.current) {
    // galaxyRings = [
    //   {
    //     size: "xs",
    //     ref: ExtraSmallRingRef,
    //     component: <ExtraSmall ref={ExtraSmallRingRef} data-size="xs" />,
    //     planets: [
    //       {
    //         size: RandomNumber(PlanetSize.min, PlanetSize.max),
    //         color: "#C9493A",
    //         speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
    //         animationDelay: RandomNumber(1, 30),
    //         story: stories[4],
    //       },
    //     ],
    //   },
    //   {
    //     size: "s",
    //     ref: SmallRingRef,
    //     component: <Small ref={SmallRingRef} data-size="s" />,
    //     planets: [
    //       {
    //         size: RandomNumber(PlanetSize.min, PlanetSize.max),
    //         color: "#63BAFF",
    //         speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
    //         animationDelay: RandomNumber(1, 30),
    //         story: stories[3],
    //       },
    //       {
    //         size: RandomNumber(PlanetSize.min, PlanetSize.max),
    //         color: "#63BAFF",
    //         speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
    //         animationDelay: RandomNumber(1, 30),
    //         story: stories[8],
    //       },
    //     ],
    //   },
    //   {
    //     size: "m",
    //     ref: MediumRingRef,
    //     component: <Medium ref={MediumRingRef} data-size="m" />,
    //     planets: [
    //       {
    //         size: RandomNumber(PlanetSize.min, PlanetSize.max),
    //         color: "#E7AC4B",
    //         speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
    //         animationDelay: RandomNumber(1, 30),
    //         story: stories[2],
    //       },
    //       {
    //         size: RandomNumber(60, 80),
    //         color: "#04c414",
    //         speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
    //         animationDelay: RandomNumber(1, 30),
    //         story: stories[0],
    //         tooltip: {
    //           content: copy[language].watchTooltip,
    //         },
    //       },
    //     ],
    //   },
    //   {
    //     size: "l",
    //     ref: LargeRingRef,
    //     component: <Large ref={LargeRingRef} data-size="l" />,
    //     planets: [
    //       {
    //         size: RandomNumber(PlanetSize.min, PlanetSize.max),
    //         color: "#EF782F",
    //         speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
    //         animationDelay: RandomNumber(1, 30),
    //         tooltip: {
    //           content: copy[language].listenTooltip,
    //         },
    //         story: stories[1],
    //       },
    //       {
    //         size: RandomNumber(PlanetSize.min, PlanetSize.max),
    //         color: "#EF782F",
    //         speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
    //         animationDelay: RandomNumber(1, 30),
    //         story: stories[6],
    //       },
    //     ],
    //   },
    //   {
    //     size: "xl",
    //     ref: ExtraLargeRingRef,
    //     component: <ExtraLarge ref={ExtraLargeRingRef} data-size="xl" />,
    //     planets: [
    //       {
    //         size: RandomNumber(PlanetSize.min, PlanetSize.max),
    //         color: "#924FFF",
    //         speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
    //         animationDelay: RandomNumber(1, 30),
    //         story: stories[5],
    //       },
    //       {
    //         size: RandomNumber(PlanetSize.min, PlanetSize.max),
    //         color: "#924FFF",
    //         speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
    //         animationDelay: RandomNumber(1, 30),
    //         story: stories[7],
    //       },
    //       {
    //         size: RandomNumber(PlanetSize.min, PlanetSize.max),
    //         color: "gray",
    //         speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
    //         animationDelay: RandomNumber(15, 30),
    //         isUploadPlanet: true,
    //       },
    //     ],
    //   },
    // ];
  }

  const onClickPlanet = (story) => {
    //setCurrentStory(story);
    showPopup("story", story.id);
  };

  useEffect(() => {
    const tl = gsap.timeline({ paused: true });
    tl.fromTo(
      RingsContainerRef.current,
      {
        x: 0,
        scale: 1,
      },
      {
        x: -200,
        scale: 1.1,
        duration: 1,
        ease: "linear",
      }
    );

    if (enlarged) {
      tl.play();
    } else {
      tl.reverse(0);
    }
  }, [enlarged]);

  useEffect(() => {
    if (galaxyRings.length > 0) {
      const ringRefs = [
        ExtraSmallRingRef,
        SmallRingRef,
        MediumRingRef,
        LargeRingRef,
        ExtraLargeRingRef,
      ];
      const newPathsObj = {};
      ringRefs.map((ringRef) => {
        ringRef.current.style.zIndex = "-1";
        ringRef.current.style.position = "relative";
        const pathD = ringRef.current.querySelector("path").getAttribute("d");
        const size = ringRef.current.dataset.size;
        newPathsObj[size] = pathD;
        return null;
      });
      setPaths(newPathsObj);
    }
  }, [galaxyRings]);

  useEffect(() => {
    setGalaxyRings([
      {
        size: "xs",
        ref: ExtraSmallRingRef,
        component: <ExtraSmall ref={ExtraSmallRingRef} data-size="xs" />,
        planets: [
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#C9493A",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
            story: stories[4],
          },
        ],
      },
      {
        size: "s",
        ref: SmallRingRef,
        component: <Small ref={SmallRingRef} data-size="s" />,
        planets: [
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#63BAFF",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
            story: stories[3],
          },
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#63BAFF",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
            story: stories[8],
          },
        ],
      },
      {
        size: "m",
        ref: MediumRingRef,
        component: <Medium ref={MediumRingRef} data-size="m" />,
        planets: [
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#E7AC4B",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
            story: stories[2],
          },
          {
            size: RandomNumber(60, 80),
            color: "#04c414",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
            story: stories[0],
            tooltip: {
              type: "watchTooltip",
            },
          },
        ],
      },
      {
        size: "l",
        ref: LargeRingRef,
        component: <Large ref={LargeRingRef} data-size="l" />,
        planets: [
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#EF782F",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
            tooltip: {
              type: "listenTooltip",
            },
            story: stories[1],
          },
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#EF782F",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
            story: stories[6],
          },
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
            story: stories[10],
          },
        ],
      },
      {
        size: "xl",
        ref: ExtraLargeRingRef,
        component: <ExtraLarge ref={ExtraLargeRingRef} data-size="xl" />,
        planets: [
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#924FFF",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
            story: stories[5],
          },
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#924FFF",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
            story: stories[7],
          },
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#924FFF",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
            story: stories[9],
          },
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "gray",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(15, 30),
            isUploadPlanet: true,
          },
        ],
      },
    ]);
  }, [stories]);

  useEffect(() => {
    const fetchStories = async () => {
      const res = await get("getApprovedStories");
      setStories(res.stories);
    };
    fetchStories();
    ComponentIsMounted.current = true;

    const storyId = searchParams.get("storyId");
    if (storyId) {
      const thisStory = Stories.find((story) => story.id === storyId);
      setCurrentStory(thisStory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <PopupWrapper
        show={popup}
        onClose={() => {
          clearPopup();
        }}
      >
        {popup === "story" && (
          <StoryPopup
            language={language}
            isVisible={popup === "story"}
            currentStory={currentStory}
          />
        )}
        {popup === "upload" && <UploadPopup language={language} />}
      </PopupWrapper>
      {/* {enlarged && <MobileStoriesPopup language={language} />} */}
      <RingsContainer ref={RingsContainerRef} enlarged={enlarged}>
        {galaxyRings.map((ring, ringIndex) => (
          <RingAndPlanetContainer key={ringIndex}>
            {ring.planets.map((planet, planetIndex) => {
              return (
                <div key={planetIndex}>
                  <Planet
                    style={{
                      display:
                        language === "en" && planet.story?.language !== "en"
                          ? "none"
                          : "flex",
                    }}
                    pathD={Paths[ring.size]}
                    ringSize={ring.size}
                    onClick={() => {
                      if (planet.isUploadPlanet) {
                        showPopup("upload");
                      } else {
                        onClickPlanet(planet.story);
                      }
                    }}
                    language={language}
                    {...planet}
                  />
                </div>
              );
            })}
            {ring.component}
          </RingAndPlanetContainer>
        ))}
      </RingsContainer>
    </>
  );
};

export default Galaxy;
