import styled from "styled-components";

const InputComp = styled.input`
  padding: 10px 5px 3px 5px;
  background: none;
  border: none;
  border-bottom: 1px solid;
  border-color: white;
  outline: none;
  color: white;
  transition: border-color 200ms;
  font-size: 16px;

  &:focus {
    border-color: #de8a33;
  }
`;

const Input = (props) => {
  return <InputComp {...props} />;
};

export default Input;
