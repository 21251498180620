import styled from "styled-components";
import Stories from "../data/Stories";

const Wrapper = styled.div`
  background: #323232;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
`;

const ListenStoriesModalContent = ({ language }) => {
  return (
    <Wrapper>
      <h1>
        {" "}
        {language === "en" ? "Listen to stories" : "Luister naar verhalen"}
      </h1>
      <h2 style={{ marginTop: "20px", marginBottom: 0 }}>Balkonplant</h2>
      <audio controls src={Stories[4].audioSrc} />
      <h2 style={{ marginTop: 30, marginBottom: 0 }}>Vuurtorens</h2>
      <audio controls src={Stories[3].audioSrc} />
      <h2 style={{ marginTop: 30, marginBottom: 0 }}>Sigur Rós</h2>
      <audio controls src={Stories[2].audioSrc} />
      <h2 style={{ marginTop: 30, marginBottom: 0 }}>Fiets</h2>
      <audio controls src={Stories[1].audioSrc} />
      <h2 style={{ marginTop: 30, marginBottom: 0 }}>Zoon zoek</h2>
      <audio controls src={Stories[5].audioSrc} />
    </Wrapper>
  );
};

export default ListenStoriesModalContent;
