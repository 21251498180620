import styled from "styled-components";
import Button from "../Button";
import { Link } from "react-router-dom";
import copy from "../../data/IntroTextCopy";

const Wrapper = styled.div`
  background-color: #1c1c1c;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CTASection = ({ language }) => {
  return (
    <Wrapper>
      <Link style={{ textDecoration: "none" }} to="/">
        <Button>{copy[language].mainCta}</Button>
      </Link>
    </Wrapper>
  );
};

export default CTASection;
