import styled, { css } from "styled-components";
import Colors from "../Colors";
import { Link } from "react-router-dom";
import { ReactComponent as StoryIcon } from "../../assets/icons/galaxy.svg";
import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { useLocation } from "react-router-dom";
import SizedBox from "../SizedBox";
import Button from "../Button";

const Wrapper = styled.div`
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  border-right: 2px solid #f5f3f4;
  background: #fafafa;
  padding: 50px 30px;
  box-sizing: border-box;
`;

const NavList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const NavHeading = styled.p`
  font-weight: 600;
`;

const NavItem = styled(Link)`
  color: #babbbd;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px 0;
  // padding: 10px 20px 10px 10px;
  // text-align: center;
  border-radius: 10px;
  width: fit-content;
  transition: color 200ms;
  position: relative;

  &::before {
    opacity: 0;
    position: absolute;
    left: -30px;
    content: "";
    width: 6px;
    height: 100%;
    background: ${Colors.primary};
    border-radius: 0 10px 10px 0;
    transition: opacity 200ms;
  }

  ${({ $active }) =>
    $active &&
    css`
      color: ${Colors.primary};

      &::before {
        opacity: 1;
      }
    `}

  &:hover {
    color: ${Colors.primary};

    &::before {
      opacity: 1;
    }
  }
`;

const NavItems = [
  {
    label: "Verhalen",
    to: "/admin/stories",
    icon: <StoryIcon />,
  },
  {
    label: "Gebruikers",
    to: "/admin/users",
    icon: <UsersIcon />,
  },
  {
    label: "Instellingen",
    to: "/settings",
    icon: <SettingsIcon />,
  },
];

const Sidebar = ({ user }) => {
  const location = useLocation();

  return (
    <Wrapper>
      <p>{user.username}</p>
      <SizedBox height="50px" />
      <NavHeading>Menu</NavHeading>
      <NavList>
        {NavItems.map((item, index) => (
          <NavItem
            $active={location.pathname.includes(item.to)}
            to={item.to}
            key={index}
          >
            {item.icon} {item.label}
          </NavItem>
        ))}
      </NavList>
      <Button onClick={() => (sessionStorage.userToken = "")} disableAnimation>
        Log out
      </Button>
    </Wrapper>
  );
};

export default Sidebar;
