import { GradientText } from "../components/Title";

const en = {
  title: (
    <>
      <span>Discover</span> <br />
      <span>wonderful</span> <GradientText>stories</GradientText> <br />
      <span>and share yours</span>
    </>
  ),
  subtitle:
    "Wonderful World is a platform that collects miraculous stories with the purpose of sharing them with you. Others' magical experiences help to open our eyes to a positive perspective where joy knows no boundaries and good faith never runs low. Let these collective stories inspire you to see the magic that is already surrounding you. This Wonderful World is yours to explore.",
  mainCta: "Discover stories",
  secondCta: "Record your story",
};

const nl = {
  title: (
    <>
      <span>Ontdek</span> <br />
      <span>wonderlijke</span> <GradientText>verhalen</GradientText> <br />
      <span>en deel die van jou</span>
    </>
  ),
  subtitle:
    "Wonderful World is een platform dat ongelofelijk bijzondere verhalen verzamelt om deze vervolgens met jou te kunnen delen. Wonderen zijn namelijk zo indrukwekkend dat ze de kijk op het leven positief kunnen veranderen. Door onze wonderlijke ervaringen met elkaar te delen, inspireren we elkaar en openen onze ogen voor meer schoonheid en hoop in de wereld. Luister naar deze unieke verhalen en deel die van jou.",
  mainCta: "Ontdek verhalen",
  secondCta: "Upload verhaal",
};

const data = {
  en,
  nl,
};

export { en, nl };
export default data;
