const en = {
  ctaListen: "Listen",
  ctaPause: "Pause",
};

const nl = {
  ctaListen: "Luister",
  ctaPause: "Pauzeer",
};

const data = { en, nl };

export default data;
