import styled from "styled-components";

const ModalEl = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
`;

const Close = styled.p`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const Modal = ({ open = true, closeModal, children }) => (
  <ModalEl open={open}>
    <Close onClick={closeModal}>Close</Close>
    {children}
  </ModalEl>
);

export default Modal;
