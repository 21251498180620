const en = {
  title: "Upload",
  inputText: "Choose file",
  cta: "Upload your story",
  recordTitle: "Record",
  successAlert: "File sent successfully!",
  successMessage: "Received",
  titleInput: "Title story",
};

const nl = {
  title: "Upload",
  inputText: "Kies bestand",
  cta: "Upload je verhaal",
  recordTitle: "Opnemen",
  successAlert: "Bestand is verstuurd!",
  successMessage: "Ontvangen",
  titleInput: "Titel verhaal",
};

const data = {
  en,
  nl,
};

export default data;
