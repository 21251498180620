import { useState, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import Button from "./old/Button";
import RecordAudio from "./RecordAudio";
import Icon from "@mdi/react";
import { mdiMicrophone } from "@mdi/js";
import { post } from "../modules/Fetch.js";

const Wrapper = styled.div`
  background: #323232;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
`;

const Input = styled.input`
  display: block;
  padding: 10px 20px;
  margin: 20px auto 10px auto;
`;

const pulse = keyframes`
    0% {
        background-color: #464646;
    }

    50% {
        background-color: #707070;
    }

    100% {
        background-color: #464646;
    }
`;

const IconButton = styled.a`
  background-color: #464646;
  display: inline-flex;
  border-radius: 100%;
  justify-content: center;
  padding: 10px;
  ${({ recording }) =>
    recording &&
    css`
      animation: ${pulse} 2s infinite;
    `}
`;

const RecordAudioModalContent = ({ language }) => {
  const [recording, setRecording] = useState(false);
  const [videoUrl, setVideoUrl] = useState(undefined);
  const [fileName, setFileName] = useState("Wonder_recording");
  const [userEmail, setUserEmail] = useState("");
  const [audio64data, setAudio64Data] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const uploadFileRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [filePresent, setFilePresent] = useState(false);

  const checkInputFiles = () => {
    if (uploadFileRef.current?.files.length) {
      setFilePresent(true);
    } else {
      setFilePresent(false);
    }
  };

  const alertUserSucces = () => {
    if (language === "en") {
      alert("Your recording has been received successfully!");
    } else if (language === "nl") {
      alert("Je opname is succesvol ontvangen!");
    }
  };

  const sendAudio = async () => {
    setIsFetching(true);
    const filePosted = await post(
      `https://api.cloudinary.com/v1_1/wonderfulworld-one/auto/upload`,
      {
        upload_preset: "wonderfulworld_stories",
        file: audio64data,
        public_id: `${userEmail}-recording-${randomNumber().toString()}`,
      },
      true,
      undefined
    );
    if (filePosted) {
      setIsFetching(false);
      alertUserSucces();
    }
  };

  const randomNumber = () => {
    return Math.floor(Math.random() * 1000);
  };

  const uploadFile = async () => {
    if (uploadFileRef.current?.files) {
      setIsUploading(true);
      const file = uploadFileRef.current.files[0];
      const formData = new FormData();
      formData.append("upload_preset", "wonderfulworld_stories");
      formData.append(
        "public_id",
        `uploaded-recording-${randomNumber().toString()}`
      );
      formData.append("file", file);
      const filePosted = await fetch(
        "https://api.cloudinary.com/v1_1/wonderfulworld-one/auto/upload",
        {
          method: "POST",
          body: formData,
        }
      ).then((res) => res.json());
      if (!filePosted.error) {
        setIsUploading(false);
        alertUserSucces();
      }
    }
  };

  return (
    <Wrapper>
      <h1>
        {language === "en" ? "Record your story!" : "Neem jouw verhaal op!"}
      </h1>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <IconButton
          onClick={() => {
            setRecording((prevState) => !prevState);
          }}
          recording={recording}
        >
          <Icon path={mdiMicrophone} size={4} />
        </IconButton>
      </div>
      <RecordAudio
        recording={recording}
        onComplete={setVideoUrl}
        setFileName={setFileName}
        setAudio64Data={setAudio64Data}
      />
      {videoUrl && (
        <div style={{ textAlign: "center" }}>
          <p style={{ fontSize: "12px", marginTop: 0 }}>
            Not happy with the recording? Click/tap the microphone to try again.
          </p>
          <Input
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder="E-mail"
            type="email"
          />
          <label
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <input
              type="checkbox"
              value={isAnonymous}
              onChange={(e) => setIsAnonymous(e.target.checked)}
            />
            Anonymous
          </label>
          <Button onClick={sendAudio}>
            {isFetching ? "Sending..." : "Send recording"}
          </Button>
        </div>
      )}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          href={videoUrl}
          isDownloadable
          downloadName={fileName}
          openTab
          isDisabled={!Boolean(videoUrl)}
        >
          Download recording
        </Button>
      </div>
      <p style={{ textAlign: "center" }}>Or:</p>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <input
          type="file"
          ref={uploadFileRef}
          accept=".mp3,.mp4,.ogg,.m4a"
          style={{ marginBottom: "10px" }}
          onChange={checkInputFiles}
        />
        <Button isDisabled={!filePresent} onClick={uploadFile}>
          {isUploading ? "Uploading..." : "Upload your story"}{" "}
        </Button>
      </div>
    </Wrapper>
  );
};

export default RecordAudioModalContent;
