import styled from "styled-components";
import LogoTrans from "../images/logo_transparant.png";

const LogoImg = styled.img`
  width: 80%;
`

const StaticContent = ({ language = "en" }) => {
  return (
    <>
      {/* <h1>Wonderful World</h1> */}
      <LogoImg src={LogoTrans} />
      {language === "en" ? (
        <>
          <p>
            A platform that will inspire you each day
            <br />
            with miraculous stories that enhance
            <br />
            your perception of reality.
          </p>

          <p>
            We love magical stories. We love the hope,
            <br />
            faith and beauty they bring to this world.
            <br />
            And most of all: we love to collect them
            <br />
            and share them with you. Is there a miracle
            <br />
            you would like to share with the world?
          </p>
          {/* <p class="text-bottom">
        Did you ever experience a miracle or
        <br />
        an incredible happening yourself?
        <br />
        Please mail to&nbsp;
        <a
          style={{ fontWeight: "bolder", color: "white" }}
          href="mailto:story@wonderfulworld.one"
        >
          story@wonderfulworld.one
        </a>
      </p> */}
        </>
      ) : (
        <>
          <p>
            Welkom bij Wonderful World, het platform dat je elke dag
            inspireert met wonderlijke verhalen die je beleving van het
            alledaagse veranderen.
          </p>
          <p>
            Wij zijn gek op wonderlijke verhalen. Wij houden van hun bijdrage
            aan hoop en de schoonheid van het ons leven.
            En we houden ervan ze te verzamelen en met je te delen. Wil jij
            jouw wonder met ons delen?
          </p>
        </>
      )}
    </>
  );
};

export default StaticContent;
