import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as RingComp } from "../assets/icons/LoaderRing.svg";

const Wrapper = styled.div`
  position: relative;
`;

const Planet = styled.div`
  width: 10px;
  height: 10px;
  position: absolute;
  background: ${({ color }) => color};
  border-radius: 50%;
  offset-path: path("${({ pathD }) => pathD}");
  animation: move 500ms infinite ease-in-out;

  @keyframes move {
    0% {
      offset-distance: 0%;
    }

    100% {
      offset-distance: 100%;
    }
  }
`;

const Ring = styled(RingComp)`
  path {
    stroke: ${({ color }) => color};
  }
`;

const Loader = ({ color = "white" }) => {
  const ringRef = useRef(null);
  const [path, setPath] = useState("");

  useEffect(() => {
    if (ringRef.current) {
      setPath(ringRef?.current?.querySelector("path")?.getAttribute("d"));
    }
  }, []);

  return (
    <Wrapper>
      <Planet color={color} pathD={path} />
      <Ring color={color} ref={ringRef} />
    </Wrapper>
  );
};

export default Loader;
