import { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as ExtraSmall } from "../assets/galaxy_rings/xs.svg";
import { ReactComponent as Small } from "../assets/galaxy_rings/s.svg";
import { ReactComponent as Medium } from "../assets/galaxy_rings/m.svg";
import { ReactComponent as Large } from "../assets/galaxy_rings/l.svg";
import { ReactComponent as ExtraLarge } from "../assets/galaxy_rings/xl.svg";
import Planet from "../components/Planet";

const RingsContainer = styled.div`
  position: absolute;
  width: 500px;
  height: 500px;
  top: 40%;
  right: 15%;
  transform-origin: center;

  svg {
    opacity: 0.5;
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    display: ${({ enlarged }) => (enlarged ? "none" : "block")};
  }
`;

const RingAndPlanetContainer = styled.div`
  position: absolute;
  right: 0;
  transform: translate(24%, -21%);
  pointer-events: none;
`;

const RandomNumber = (min = 0, max = 1) =>
  Math.floor(Math.random() * max) + min;

const PlanetSize = {
  min: 30,
  max: 60,
};

const PlanetSpeed = {
  min: 20,
  max: 200,
};

let galaxyRings;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  // z-index: 1;
  overflow: hidden;
  opacity: 0.2;
  pointer-events: none;
`;

const BackgroundGalaxy = () => {
  const ExtraSmallRingRef = useRef(null);
  const SmallRingRef = useRef(null);
  const MediumRingRef = useRef(null);
  const LargeRingRef = useRef(null);
  const ExtraLargeRingRef = useRef(null);
  const [Paths, setPaths] = useState({
    xs: "",
    s: "",
    m: "",
    l: "",
    xl: "",
  });
  const ComponentIsMounted = useRef(false);
  const RingsContainerRef = useRef(null);

  if (!ComponentIsMounted.current) {
    galaxyRings = [
      {
        size: "xs",
        ref: ExtraSmallRingRef,
        component: <ExtraSmall ref={ExtraSmallRingRef} data-size="xs" />,
        planets: [
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#C9493A",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
          },
        ],
      },
      {
        size: "s",
        ref: SmallRingRef,
        component: <Small ref={SmallRingRef} data-size="s" />,
        planets: [
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#63BAFF",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
          },
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#63BAFF",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
          },
        ],
      },
      {
        size: "m",
        ref: MediumRingRef,
        component: <Medium ref={MediumRingRef} data-size="m" />,
        planets: [
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#E7AC4B",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
          },
          {
            size: RandomNumber(60, 80),
            color: "#04c414",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
            story: {
              videoSrc: true,
            },
          },
        ],
      },
      {
        size: "l",
        ref: LargeRingRef,
        component: <Large ref={LargeRingRef} data-size="l" />,
        planets: [
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#EF782F",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
          },
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#EF782F",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
          },
        ],
      },
      {
        size: "xl",
        ref: ExtraLargeRingRef,
        component: <ExtraLarge ref={ExtraLargeRingRef} data-size="xl" />,
        planets: [
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#924FFF",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
          },
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "#924FFF",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(1, 30),
          },
          {
            size: RandomNumber(PlanetSize.min, PlanetSize.max),
            color: "gray",
            speed: RandomNumber(PlanetSpeed.min, PlanetSpeed.max),
            animationDelay: RandomNumber(15, 30),
            isUploadPlanet: true,
          },
        ],
      },
    ];
  }

  useEffect(() => {
    ComponentIsMounted.current = true;
    const ringRefs = [
      ExtraSmallRingRef,
      SmallRingRef,
      MediumRingRef,
      LargeRingRef,
      ExtraLargeRingRef,
    ];
    const newPathsObj = {};
    ringRefs.map((ringRef) => {
      ringRef.current.style.zIndex = "-1";
      ringRef.current.style.position = "relative";
      const pathD = ringRef.current.querySelector("path").getAttribute("d");
      const size = ringRef.current.dataset.size;
      newPathsObj[size] = pathD;
      return null;
    });
    setPaths(newPathsObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <RingsContainer ref={RingsContainerRef}>
        {galaxyRings.map((ring, ringIndex) => (
          <RingAndPlanetContainer key={ringIndex}>
            {ring.planets.map((planet, planetIndex) => {
              return (
                <div key={planetIndex}>
                  <Planet
                    // style={{
                    //   display:
                    //     language === "en" && planet.story?.language !== "en"
                    //       ? "none"
                    //       : "flex",
                    // }}
                    pathD={Paths[ring.size]}
                    ringSize={ring.size}
                    story={{ audioSrc: true }}
                    showIcon={false}
                    {...planet}
                  />
                </div>
              );
            })}
            {ring.component}
          </RingAndPlanetContainer>
        ))}
      </RingsContainer>
    </Wrapper>
  );
};

export default BackgroundGalaxy;
