import { useEffect, useRef, useState } from "react";

let mediaRecorder = null;

const startRecord = (stream, audioRef, onComplete, setFileName, setAudio64Data) => {
  const audioChuncks = [];

  mediaRecorder = new MediaRecorder(stream);
  mediaRecorder.start();

  mediaRecorder.addEventListener("dataavailable", (event) => {
    audioChuncks.push(event.data);
  });

  mediaRecorder.addEventListener("stop", async () => {
    const audioBlob = new Blob(audioChuncks, { type: "audio/ogg" });
    const audioUrl = URL.createObjectURL(audioBlob);
    audioRef.current.src = audioUrl;
    onComplete(audioUrl);
    setFileName("wonderfulworld_recording.ogg");

    const reader = new FileReader();
    reader.readAsDataURL(audioBlob);
    reader.onloadend = async () => {
      const base64data = reader.result;
      setAudio64Data(base64data);
    };
    //   const audio = new Audio(audioUrl);
    //   audio.play()
    mediaRecorder = null;
  });
};

const stopRecording = () => {
  if (mediaRecorder) {
    mediaRecorder.stop();
  }
};

let interval;

const RecordAudio = ({ recording, onComplete, setFileName, setAudio64Data }) => {
  const audioRef = useRef(null);
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (recording) {
      setTime(0);
      const beginStream = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        startRecord(stream, audioRef, onComplete, setFileName, setAudio64Data);

        interval = setInterval(() => {
          setTime((prevTime) => prevTime + 1000);
        }, 1000);
      };
      beginStream();
    } else {
      stopRecording();
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording]);
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "5px",
        marginBottom: "5px",
      }}
    >
      <p>
        <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
        <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
      </p>
      <audio ref={audioRef} controls></audio>
    </div>
  );
};

export default RecordAudio;
