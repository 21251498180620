import { useRef, useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { useSplitText } from "../composables/useSplitText";

const SubtitleText = styled.p`
  color: white;
  // font-family: "Avenir Next";
  font-size: 18px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const Subtitle = ({ children }) => {
  const TextRef = useRef(null);

  useEffect(() => {
    const splitText = async () => {
      const tl = gsap.timeline();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const mySplitText = await useSplitText(TextRef.current);

      tl.from(mySplitText.splitText.lines, {
        duration: 0.8,
        opacity: 0,
        y: 30,
        stagger: 0.1,
        delay: 0.5,
      });
    };
    splitText();
  }, []);

  return <SubtitleText ref={TextRef}>{children}</SubtitleText>;
};

export default Subtitle;
