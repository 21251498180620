import { useEffect, useState } from "react";
import styled from "styled-components";
import { get } from "../../modules/Fetch";
import Table from "./Table";
import { Outlet } from "react-router-dom";

export const Wrapper = styled.div``;

export const Heading = styled.h2``;

const columns = [
  {
    label: "Unieke identificatie",
    key: "id",
  },
  {
    label: "Titel",
    key: "title",
  },
  {
    label: "Engelse titel",
    key: "enTitle",
  },
  {
    label: "Audio bestand",
    key: "audioSrc",
  },
  {
    label: "Video bestand",
    key: "videoSrc",
  },
  {
    label: "User",
    key: "userEmail",
  },
  {
    label: "Goedgekeurd / online",
    key: "approved",
  },
];

const Stories = () => {
  const [allStories, setStories] = useState([]);

  useEffect(() => {
    const getStories = async () => {
      const { stories } = await get("getAllStories");
      if (stories) {
        setStories(stories);
      }
    };
    getStories();
  }, []);

  return (
    <Wrapper>
      <Heading>Stories</Heading>
      <Table columns={columns} items={allStories} areStories />
      <Outlet />
    </Wrapper>
  );
};

export default Stories;
