const en = {
  uploadTooltip: "Upload here",
  watchTooltip: "Watch here",
  listenTooltip: "Listen here",
};

const nl = {
  uploadTooltip: "Upload hier",
  watchTooltip: "Bekijk nu",
  listenTooltip: "Luister nu",
};

const data = {
  en,
  nl,
};

export default data;
