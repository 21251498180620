import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useLayoutEffect,
} from "react";
import styled from "styled-components";
import Header from "../components/Header";
import copy from "../data/AboutCopy";
import { GradientText } from "../components/Title";
import { ReactComponent as PlanetOrbit } from "../assets/about_planet_orbit.svg";
// import { ReactComponent as RealisticMoon } from "../assets/about_real_moon.svg";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Colors from "../components/Colors";
import { useSplitText } from "../composables/useSplitText";
import SizedBox from "../components/SizedBox";
import Founder from "../components/Founder.jsx";
import Laura from "../images/Laura2.JPG";
import Kim from "../images/kim2.JPG";
import Dennis from "../images/Dennis.jpg";
import { useMediaQuery } from "react-responsive";
import BackgroundGalaxy from "../components/BackgroundGalaxy.jsx";
import CTASection from "../components/about/CTASection.jsx";

gsap.registerPlugin(ScrollTrigger);

const Founders = [
  {
    name: "Dennis Karpes",
    position: "Co-founder",
    role: "Strategic Director",
    image: Dennis,
    description:
      "“If you can imagine it, you can achieve it. If you can dream it, you can become it.” It’s a saying that couldn’t fit Dennis, known for three social ventures (Kuyichi, Dance4Life, JustDiggit) better. A real visionair that went beyond the limiting belief that he himself couldn't change anything about the challenges we are facing in our world. He understands that the world is bigger and beyond his own existence, he wants his efforts to reward the world around him too. Leading people beyond their limited beliefs and empowering them to do something beyond their imagination.",
  },
  {
    name: "Laura van Ree",
    position: "Co-founder",
    role: "Brand Director",
    image: Laura,
    description:
      "Growing up in the Netherlands with an Indonesian mother, Laura has always been exposed to magical thinking. From the Indonesian folklore stories that were told to her by her grandmother, to heroic stories about a forbidden colonial past… Always being reminded how grateful she had to be to have been growing up in a country that’s not in a war situation, she has learned how gratitude and magic go hand in hand. How they could form our imagination and creativity that brings us to places that we couldn’t even dream of before. In the midst of living a magical life, her goal is to introduce people to a wonderful world, imagining themselves to be bigger than they allow themselves to be. With her background in philosophy she’s in for an existential shift!",
  },
  {
    name: "Kim Nieuwenhuijs-Griffioen",
    position: "Co-founder",
    role: "Creative Director",
    image: Kim,
    description:
      "Creative entrepreneur at heart and soul, Kim has a great imagination with a keen eye for communication and design. But above all a strong belief in miraculous coincidences that are too adventitious to just occur. The power of the universe is here, we just have to recognise it. Starting WonderfulWorld together with Dennis and Laura was truly the right project at the right time. Having experienced a lot of grief and trauma herself, she also sees the beauty and force of it. Kickstarting this platform and realizing all our ideas in the coming years is a dream coming true. Let’s record all beautiful happenings around us to inspire others and empower it through all kinds of media.",
  },
];

const nlFounders = JSON.parse(JSON.stringify(Founders));
nlFounders[0].role = "Strategisch Directeur";
nlFounders[0].description =
  "“Als je het je kan voorstellen, dan kan je het bereiken. Als je het kan dromen, dan kan je het worden.” Het is een gezegde dat Dennis, bekend van drie sociale ondernemingen (Kuyichi, Dance4Life, JustDiggit) op het lijf geschreven is. Een echte visionair die verder gaat dan de beperkende overtuiging dat hij zelf niets kon veranderen aan de uitdagingen waar we in onze wereld voor staan. Hij wilt dat zijn inspanningen ook de wereld om hem heen positief veranderen. Door mensen buiten hun beperkte overtuigingen te leiden en hen in staat te stellen iets te doen dat hun verbeelding te boven gaan.";
nlFounders[1].role = "Brand Directeur";
nlFounders[1].description =
  "Laura groeide op in Nederland met een Indonesische moeder en is altijd blootgesteld geweest aan magisch denken. Van de Indonesische folklore verhalen die haar grootmoeder haar vertelde, tot heroïsche verhalen over een verboden koloniaal verleden… Steeds worden herinnerd hoe dankbaar ze moest zijn om op te groeien in een land dat niet in een oorlogssituatie verkeert, heeft ze geleerd hoe dankbaarheid en magie hand in hand gaan. Hoe ze onze verbeelding en creativiteit kunnen vormen die ons naar plaatsen brengt waar we eerder niet eens van konden dromen. Terwijl ze een magisch leven leidt, is het haar doel om mensen kennis te laten maken met een wondere wereld, waarbij ze zich groter inbeelden dan ze zichzelf toestaan ​​te zijn. Met haar achtergrond in de filosofie is ze klaar voor een existentiële shift!";
nlFounders[2].role = "Creatief Directeur";
nlFounders[2].description =
  "Creatief ondernemer in hart en nieren maakt dat Kim een grote fantasie heeft met een scherp oog voor communicatie en design. Maar vooral een sterk geloof in wonderbaarlijke toevalligheden die te toevallig zijn om zomaar te gebeuren. De kracht van het universum is hier, we moeten het alleen herkennen. Het samen met Dennis en Laura starten van WonderfulWorld was echt het juiste project op het juiste moment. Ze heeft zelf veel verdriet en trauma meegemaakt en ziet daar ook de schoonheid en kracht van in. Dit platform een ​​kickstart geven en al onze ideeën de komende jaren realiseren is een droom die uitkomt. Laten we alle mooie gebeurtenissen om ons heen vastleggen om anderen te inspireren en verspreiden via allerlei media.";

const newCopy = {
  en: {
    heading: (
      <>
        <span>We are</span>
        <br />
        <GradientText>Wonderful World</GradientText>
      </>
    ),
    aboutTitle: "About us",
    about: [
      `We live in exciting times. Within a world where we can almost solve and
      understand everything through science - there’s a world that solely
      lives in our experience. It’s a world of hope, of connection, of wonder
      and surprise. It’s a world that creates memories so vivid and alive that
      we can only share them in the most colorful and engaging stories. It’s
      the world of magic.`,
      `You never forget a magic moment. They are the heroic
      stories your grandparents tell you, your children and their children.
      They are part of us, of the way we move through life, they are our
      breath, our heartbeat, our song… They are our memories of a faraway
      past. As long as humankind exists, there are magical stories shared
      amongst each other. We need them as much as we need air to breathe from.
      Magic is at the base of our creativity, hope, understanding, comfort and
      love for life. With the imagination that magic brings, we can create and
      rethink a new and developing world.`,
      `That’s why we need to honor these stories, and it’s exactly what we’re
      going to do. Share! Tell us! Take us with you in your personal
      experience with magic. The world needs your story!`,
    ],
    visionTitle: "Vision",
    vision: [
      `The world is at a tipping point, where we are facing enormous challenges
      such as climate disasters, a pandemic, worldwide famine, the
      influx of (political) migrants, economical crisis, and this list can go
      on. In times of crisis people automatically turn to the unexplainable
      world, because this world gives us a sense of control.`,
      `What do we need right now? Hearts full of hope and creativity. And
      that’s exactly what a Wonderful World intends to do. We want to share
      the world of magic, showing our people that magic moments are not just
      rare moments of bliss, but that they’re part of your day to day
      experience. It’s a positive reaction to the anxiety that we can feel in
      times of crisis. It’s joy, being bewildered, surprised and comforted.
      Let’s imagine a better world together… and be able to build one!
      Thinking that we can’t change something individually is the mindset
      people suffer from most. We’d love to think that you can do anything …
      If you can imagine it.`,
    ],
    foundersTitle: "Founders",
    founders: Founders,
  },
  nl: {
    heading: (
      <>
        <span>Wij zijn</span>
        <br />
        <GradientText>Wonderful World</GradientText>
      </>
    ),
    aboutTitle: "Ons verhaal",
    about: [
      `We leven in bijzondere tijden. In een wereld waar we bijna alles kunnen
      oplossen en begrijpen door middel van wetenschap, bevindt zich een
      wereld die uitsluitend in onze ervaring leeft. Het is een wereld van
      hoop, van verbinding, van verwondering en verrassing. Het is een wereld
      die herinneringen creëert die zo indrukwekkend en levendig zijn dat we
      ze alleen kunnen delen in de meest kleurrijke en boeiende verhalen. Het
      is de wereld van magie.`,
      `Een wonder vergeet je nooit. Het zijn de heroïsche verhalen die je
      grootouders je, je kinderen en hun kinderen vertellen. Ze maken deel uit
      van ons, van de manier waarop we ons door het leven bewegen, ze zijn
      onze adem, onze hartslag, ons lied... Het zijn onze herinneringen aan
      een ver verleden. Zolang de mensheid bestaat, worden magische verhalen
      met elkaar gedeeld. We hebben ze net zo hard nodig als lucht om uit te
      ademen. Magie ligt aan de basis van onze creativiteit, hoop, begrip,
      troost en liefde voor het leven. Met de verbeeldingskracht die magie met
      zich meebrengt, kunnen we een nieuwe en zich ontwikkelende wereld
      creëren en heroverwegen.`,
      `Daarom moeten we deze verhalen eren, en dat is precies wat we gaan doen.
      Delen! Vertel ons! Neem ons mee in je persoonlijke ervaring met magie. De wereld heeft jouw verhaal nodig!`,
    ],
    visionTitle: "Onze visie",
    vision: [
      `De wereld staat op een kantelpunt, waar we voor enorme uitdagingen
      staan, zoals klimaatrampen, een wereldwijde pandemie, wereldwijde
      hongersnood, de toestroom van (politieke) migranten, economische crisis,
      en deze lijst kan maar doorgaan. In tijden van crisis wenden mensen zich
      automatisch tot de onverklaarbare wereld, omdat deze wereld ons een
      gevoel van controle geeft.`,
      `Wat hebben we nu nodig? Harten vol hoop en creativiteit. En dat is
      precies wat Wonderful World van plan is te doen. We willen wonderen
      delen en laten zien dat magische momenten niet alleen zeldzame momenten
      van gelukzaligheid zijn, maar dat ze deel uitmaken van je dagelijkse
      ervaring. Het is een positieve reactie op de angst die we kunnen voelen
      in tijden van crisis. Gevoelens van vreugde, verbazing, verrassing en
      troost delen. Laten we ons samen een betere wereld voorstellen... en er
      een bouwen! Denken dat we iets niet individueel kunnen veranderen is de
      mentaliteit waar mensen het meest aan lijden. We denken graag dat je
      alles kunt doen... als je het maar kunt voorstellen.`,
    ],
    foundersTitle: "Oprichters",
    founders: nlFounders,
  },
};

const Page = styled.div`
  width: 100vw;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  background: #1c1c1c;
  background-image: linear-gradient(145deg, #1c1c1c, #242424);
  // padding: 1em;
  min-height: 100vh;
`;

const Container = styled.div`
  position: relative;
  z-index: 1;
  padding: 2em 50px;
  // text-align: right;

  @media screen and (max-width: 768px) {
    padding: 2em 25px;
  }

  > div:first-child {
    h2 {
      @media screen and (min-width: 768px) {
        text-align: right;
      }
    }
    p {
      margin-left: auto;
    }
  }
`;

const TextContainer = styled.div``;

const Text = styled.p`
  color: white;
  width: 50%;
  font-size: 18px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Heading = styled.h1`
  color: white;
  font-size: 78px;
  font-weight: 200;
  font-family: Fraunces;
  margin-bottom: 0;
  margin-top: 0.3em;

  @media screen and (max-width: 768px) {
    font-size: 42px;
  }
`;

const Heading2 = styled.h2`
  font-family: "Fraunces";
  color: white;
  font-size: 42px;
  // font-weight: 900;
  // text-transform: uppercase;
  overflow: hidden;
  line-height: 1;
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 200;

  span {
    display: block;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 100px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
  }

  > * {
    width: 100%;
  }

  p {
    width: 100%;
  }
`;

const VisualContainer = styled.div`
  //width: 33%;
`;

const ConnectorContainer = styled.div`
  /* width: 100%;
    height: auto; */
  position: relative;
  margin-top: -5%;
`;

const BottomCircleConnector = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  // bottom: -60px;
  z-index: 0;
  left: 50%;
  background: #fab546;
  transform-origin: center;
`;

const InnerConnectorBackground = styled.div`
  // background: #fab546;
`;

const VisionContainer = styled.div`
  background: #1c1c1c;
  //padding: 2em 50px;
`;

const InnerVision = styled.div`
  padding: 2em 100px;
  text-align: right;

  p {
    margin-left: auto;
  }

  @media screen and (max-width: 768px) {
    padding: 2em 20px;
  }
`;

const About2 = () => {
  const [language, setLanguage] = useState(localStorage.language || "en");
  const ConnectorContainerRef = useRef(null);
  const ConnectorRef = useRef(null);
  const WrapperRef = useRef(null);
  const ContainerContentRef = useRef(null);
  const MoonRef = useRef(null);
  const Heading2Refs = useRef([]);
  const TextRefs = useRef([]);
  const VisionHeadingRef = useRef(null);
  const VisionTextRefs = useRef([]);
  const VisionContainerRef = useRef(null);

  useEffect(() => {
    localStorage.language = language;
  }, [language]);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useLayoutEffect(() => {
    const tl = gsap.timeline({ paused: true });
    tl.fromTo(
      Heading2Refs.current,
      {
        yPercent: 100,
      },
      {
        yPercent: 0,
      }
    );
    tl.fromTo(
      TextRefs.current,
      {
        y: 30,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        delay: 0.5,
      }
    );
    tl.play();

    document.onreadystatechange = () => {
      if (VisionContainerRef.current) {
        const visionTl = gsap.timeline();
        visionTl.fromTo(
          VisionHeadingRef.current,
          {
            yPercent: 100,
          },
          {
            yPercent: 0,
          }
        );
        visionTl.fromTo(
          VisionTextRefs.current,
          {
            y: 30,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            stagger: 0.2,
          }
        );

        ScrollTrigger.create({
          trigger: WrapperRef.current,
          start: "center 25%",
          markers: false,
          animation: visionTl,
        });
      }
    };
  }, []);

  return (
    <div>
      <BackgroundGalaxy />
      <Wrapper ref={WrapperRef}>
        <Header language={language} changeLanguage={setLanguage} />
        <HeaderContent>
          <div>
            <Heading>{newCopy[language].heading}</Heading>
            {newCopy[language].about.map((paragraph, index) => (
              <Text ref={(e) => (TextRefs.current[index] = e)}>
                {paragraph}
              </Text>
            ))}
          </div>
          <VisualContainer>{/* <PlanetOrbit /> */}</VisualContainer>
        </HeaderContent>
      </Wrapper>
      <VisionContainer ref={VisionContainerRef}>
        <InnerVision>
          <Heading2>
            <span ref={VisionHeadingRef}>{newCopy[language].visionTitle}</span>
          </Heading2>
          {newCopy[language].vision.map((paragraph, index) => (
            <Text ref={(e) => (VisionTextRefs.current[index] = e)}>
              {paragraph}
            </Text>
          ))}
        </InnerVision>
      </VisionContainer>
      {newCopy[language].founders.map((founder, index) => (
        <Founder key={index} index={index} {...founder} />
      ))}
      <CTASection language={language} />
    </div>
  );
};

export default About2;
