import { useState, useEffect } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import copy, { Text } from "../data/AboutCopy";

const Wrapper = styled.div`
  background: black;
  height: 100%;
  min-height: 100vh;
`;

const Container = styled.div`
  padding: 1em;
`;

const FoundersContainer = styled.div``;

const Heading2 = styled.h2`
  color: white;
`;

const Founder = styled.div`
  border-radius: 10px;
  padding: 20px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: fit-content;
`;

const FounderName = styled(Text)`
  font-size: 32px;
  margin: 0;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -75%);
  width: 100%;
  text-align: center;
`;
const FounderRole = styled(Text)`
  font-style: italic;
`;
const FounderImage = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
  object-position: 50% 42%;
  opacity: 0.7;
`;
const FounderDesc = styled(Text)`
  max-width: 750px;
`;

const Founders = () => {
  const [language, setLanguage] = useState(localStorage.language || "en");

  useEffect(() => {
    localStorage.language = language;
  }, [language]);

  return (
    <Wrapper>
      <Header language={language} changeLanguage={setLanguage} />
      <Container>
        <Heading2>{copy[language].foundersTitle}</Heading2>
        <Text>{copy[language].initiative}</Text>
        <FoundersContainer>
          {copy[language].founders.map((founder) => (
            <Founder>
              <ImageContainer>
                <FounderImage src={founder.image} />
                <FounderName>{founder.name}</FounderName>
              </ImageContainer>
              <FounderRole>{founder.role}</FounderRole>
              <FounderDesc>{founder.description}</FounderDesc>
            </Founder>
          ))}
        </FoundersContainer>
      </Container>
    </Wrapper>
  );
};

export default Founders;
