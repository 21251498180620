import { useRef, useEffect } from "react";
import gsap from "gsap";
import styled from "styled-components";
import SizedBox from "../SizedBox";
import Title from "../Title.jsx";
import Subtitle from "../Subtitle";
import Button from "../Button.jsx";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";
import { useSearchParams } from "react-router-dom";
import copy from "../../data/IntroTextCopy";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  z-index: 2;
  width: fit-content;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: auto;
    height: unset;
  }
`;

const InfoContainer = styled.div`
  width: fit-content;
  max-width: 600px;
  margin-left: 75px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  text-align: right;

  @media screen and (max-width: 768px) {
    text-align: left;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: fit-content;

    > * {
      &:first-child {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-top: 10px;
      }
    }
  }
`;

const IntroText = ({ language, enlarged, enlargeGalaxy }) => {
  const WrapperRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (enlarged) {
      gsap.set(WrapperRef.current, {
        opacity: 0,
        visibility: "hidden",
        display: "none",
      });
    } else {
      gsap.set(WrapperRef.current, {
        opacity: 1,
        visibility: "visible",
        display: "block",
      });
    }
  }, [enlarged]);

  const transitionToGalaxy = () => {
    const tl = gsap.timeline();

    tl.to(WrapperRef.current, {
      y: -200,
      opacity: 0,
      duration: 1,
      onComplete: () => {
        gsap.set(WrapperRef.current, { y: 0, display: "none" });
        enlargeGalaxy(true);
      },
    });
  };

  const onNavigateRecord = () => {
    searchParams.set("popup", "upload");
    setSearchParams(searchParams);
  };

  return (
    <Wrapper ref={WrapperRef}>
      <InfoContainer>
        <TitleContainer>
          <Title language={language}>{copy[language].title}</Title>
        </TitleContainer>
        <Subtitle>{copy[language].subtitle}</Subtitle>
        <SizedBox />
        <ButtonsContainer>
          <Button onClick={transitionToGalaxy} icon={<ArrowIcon />}>
            {copy[language].mainCta}
          </Button>
          <Button
            onClick={onNavigateRecord}
            icon={<ArrowIcon />}
            mode="secondary"
          >
            {copy[language].secondCta}
          </Button>
        </ButtonsContainer>
        <SizedBox />
      </InfoContainer>
    </Wrapper>
  );
};

export default IntroText;
