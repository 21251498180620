import styled from "styled-components";
import { ReactComponent as Close } from "../assets/icons/close.svg";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 1;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  z-index: 20;

  @media screen and (max-width: 768px) {
    // display: ${({ show }) => show === "story" && "none"};
  }
`;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 50px;
  top: 50px;
  cursor: pointer;
`;

const PopupWrapper = ({ show, onClose, children }) => {
  return (
    <Wrapper show={show}>
      <CloseIconContainer onClick={onClose}>
        <Close />
      </CloseIconContainer>
      {children}
    </Wrapper>
  );
};

export default PopupWrapper;
