import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import Input from "./Input";
import Button from "./Button";
import SizedBox from "./SizedBox";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import copy from "../data/HeaderCopy";
import { post } from "../modules/Fetch";
import { isProd } from "../modules/socket";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  opacity: 0;
  visibility: hidden;
`;

const Container = styled.div`
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: black;
  padding: 10px 40px 30px 40px;
  border: 1px solid #ec5f41;
  border-radius: 5px;

  @media screen and (max-width: 768px) {
    width: 75%;
  }
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const Heading = styled.h2`
  color: white;
  margin-bottom: 0;
`;

const GetNotifiedPopup = ({ language, show, onClose }) => {
  const WrapperRef = useRef(null);
  const [formSucess, setFormSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const FadeOut = () => {
    const tl = gsap
      .timeline({ paused: true })
      .to(WrapperRef.current, {
        opacity: 0,
      })
      .to(
        WrapperRef.current,
        {
          visibility: "hidden",
        },
        ">"
      );
    tl.play();
  };

  const handleForm = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const sendForm = async () => {
      setIsLoading(true);
      if (isProd) {
        const res = await fetch("https://formspree.io/f/myyoopzw", {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: data,
        }).then((res) => res);
        if (res.ok) {
          setIsLoading(false);
          setFormSuccess(true);
        }
      } else {
        const { status } = await post("subscribe", {
          email,
          preferredLanguage: language,
        });

        if (status === "alreadySubscribed") {
          setIsLoading(false);
          alert("You are already subscribed!");
        } else if (status === "success") {
          setIsLoading(false);
          setFormSuccess(true);
        } else {
          alert("error");
        }
      }
    };
    sendForm();
  };

  useEffect(() => {
    if (show) {
      gsap.to(WrapperRef.current, {
        opacity: 1,
        visibility: "visible",
      });
    }
  }, [show]);

  return (
    <Wrapper
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          FadeOut();
          onClose();
        }
      }}
      ref={WrapperRef}
    >
      <Container>
        <CloseContainer
          onClick={() => {
            FadeOut();
            onClose();
          }}
        >
          <Close />
        </CloseContainer>
        <Heading>{copy[language].getNotified.popupTitle}</Heading>
        <SizedBox height="30px" />
        <form
          onSubmit={handleForm}
          action="https://formspree.io/f/myyoopzw"
          method="POST"
        >
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            type="email"
            placeholder="E-mail"
          />
          <SizedBox />
          <Button loading={isLoading} size="s" disableAnimation>
            {formSucess
              ? copy[language].getNotified.success
              : copy[language].getNotified.cta}
          </Button>
        </form>
      </Container>
    </Wrapper>
  );
};

export default GetNotifiedPopup;
