import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useSplitText } from "../composables/useSplitText";

const Wrapper = styled.div``;

const Container = styled.div`
  padding: 2em 50px;
  display: flex;
  align-items: center;
  flex-direction: ${({ even }) => (even ? "row-reverse" : "row")};
  background: ${({ even }) => (even ? "#252525" : "#1c1c1c")};

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }

  ${({ even }) =>
    !even &&
    css`
      h2,
      span {
        text-align: right;
      }
    `}

  > div {
    width: 100%;
  }
`;

const InfoContainer = styled.div``;

const NameTitle = styled.h2`
  color: white;
  margin: 0;
  font-size: 32px;
  overflow: hidden;
  font-family: "Fraunces";
  font-weight: 200;

  span {
    display: block;
  }
`;

const Role = styled.span`
  color: white;
  display: block;
  overflow: hidden;

  span {
    display: block;
  }
`;

const Description = styled.p`
  color: white;
  text-align: ${({ even }) => (even ? "left" : "right")};

  @media screen and (max-width: 768px) {
    text-align: left;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
  width: 40%;
  max-height: 400px;

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Founder = ({ name, position, role, image, description, index }) => {
  const WrapperRef = useRef(null);
  const TextRefs = useRef([]);
  const DescriptionRef = useRef(null);
  const ImageRef = useRef(null);

  useEffect(() => {
    const setAnimations = async () => {
      if (
        WrapperRef.current &&
        TextRefs.current &&
        DescriptionRef.current &&
        ImageRef.current
      ) {
        const tl = gsap.timeline();

        const isEven = index % 2 === 0;

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const mySplitText = await useSplitText(DescriptionRef.current);

        tl.fromTo(
          TextRefs.current,
          {
            yPercent: 100,
          },
          {
            yPercent: 0,
          }
        );

        tl.from(mySplitText.splitText.lines, {
          duration: 0.8,
          opacity: 0,
          y: 60,
          stagger: 0.1,
        });

        tl.fromTo(
          ImageRef.current,
          {
            x: isEven ? 50 : -50,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            rotate: 5,
          }
        );

        ScrollTrigger.create({
          trigger: WrapperRef.current,
          start: "top 75%",
          markers: false,
          animation: tl,
        });
      }
    };

    setAnimations();
  }, []);

  return (
    <Wrapper ref={WrapperRef}>
      <Container even={index % 2 === 0}>
        <ImageWrapper>
          <ImageContainer ref={ImageRef}>
            <Image src={image} />
          </ImageContainer>
        </ImageWrapper>
        <InfoContainer>
          <NameTitle>
            <span ref={(e) => TextRefs.current.push(e)}>{name}</span>
          </NameTitle>
          <Role>
            <span ref={(e) => TextRefs.current.push(e)}>{role}</span>
          </Role>
          <Description even={index % 2 === 0} ref={DescriptionRef}>
            {description}
          </Description>
        </InfoContainer>
      </Container>
    </Wrapper>
  );
};

export default Founder;
