import io from "socket.io-client";

const serverEndpoint = window.location.origin.includes("localhost") ? "http://localhost:3001" : window.location.origin; // process.env.REACT_APP_SERVER_ENDPOINT; //"http://localhost:3001"

export const isProd =
  process.env.NODE_ENV === "production" && !!!process.env.REACT_APP_ISHEROKU;

const { userToken } = sessionStorage;
export const socket = !isProd
  ? io(serverEndpoint, {
      query: { token: userToken },
    })
  : null;