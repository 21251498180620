import { useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
`;

const Container = styled.div`
  width: 40%;
  background: white;
  border-radius: 10px;
  padding: 20px;
  transform: scale(0);
`;

const Popup = ({ show, children }) => {
  const WrapperRef = useRef(null);
  const ContainerRef = useRef(null);

  useLayoutEffect(() => {
    if (show) {
      gsap.set(WrapperRef.current, { visibility: "visible" });
      const tl = gsap.timeline({ paused: true });
      tl.fromTo(
        WrapperRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        }
      );

      tl.fromTo(
        ContainerRef.current,
        {
          scale: 0,
        },
        {
          scale: 1,
        }
      );
      tl.play();
    } else {
      const tl = gsap.timeline({ paused: true });

      tl.to(ContainerRef.current, {
        scale: 0,
      });

      tl.to(WrapperRef.current, {
        opacity: 0,
        onComplete: () =>
          gsap.set(WrapperRef.current, { visibility: "hidden" }),
      });

      tl.play();
    }
  }, [show]);

  return (
    <Wrapper ref={WrapperRef}>
      <Container ref={ContainerRef}>{children}</Container>
    </Wrapper>
  );
};

export default Popup;
