import { useEffect, useState } from "react";
import { Wrapper, Heading } from "./Stories";
import { get } from "../../modules/Fetch";
import Table from "./Table";

const columns = [
  {
    label: "Gebruikersnaam",
    key: "username",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Geabonneerd",
    key: "subscribed",
  },
  {
    label: "Heeft account",
    key: "hasAccount",
  },
];

const Users = () => {
  const [allUsers, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const { users } = await get("getUsers");
      if (users) {
        setUsers(users);
        console.log(users);
      }
    };
    getUsers();
  }, []);

  return (
    <Wrapper>
      <Heading>Users</Heading>
      <Table columns={columns} items={allUsers} />
      {/* <Table>
        <thead>
          <tr>
            {columns.map((column, i) => (
              <th key={i}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allUsers.map((user, i) => (
            <tr key={i}>
              {columns.map((column, index) => (
                <td key={index}>{user[column.key]?.toString()}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table> */}
    </Wrapper>
  );
};

export default Users;
