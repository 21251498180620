import { useState } from "react";
import styled from "styled-components";
import InputComp from "../../components/Input";
import Button from "../../components/Button";
import SizedBox from "../../components/SizedBox";
import { socket } from "../../modules/socket";
import { post } from "../../modules/Fetch";
import logoImg from "../../images/logo_transparant.png";

const Wrapper = styled.div`
  background-color: #eee;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
`;

const Container = styled.div`
  background-color: white;
  max-width: 75%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 40px 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 200px;
`;

const InputsContainer = styled.div`
  /* display: flex;
  flex-direction: column; */
`;

const Input = styled(InputComp)`
  color: black;
  border-color: black;
  display: block;
`;

const Login = ({ setIsLoggedIn, setUser }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      onLogin();
    }
  };

  const onLogin = async () => {
    if (email && password) {
      const res = await post("login", {
        email,
        password,
      });
      if (res) {
        setUser(res.user);
        sessionStorage.userToken = res.user.token;
        socket.io.engine.opts.query.token = res.user.token;
        socket.disconnect().connect(); // so token gets updated in socket
        setIsLoggedIn(true);
      }
    }
  };
  return (
    <Wrapper>
      <Container>
        <Image alt="Logo" src={logoImg} />
        <SizedBox />
        <InputsContainer>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email"
          />
          <SizedBox />
          <Input
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={handleKeyUp}
            type="password"
            placeholder="password"
          />
        </InputsContainer>
        <SizedBox height="100px" />
        <Button onClick={onLogin} disableAnimation>
          Login
        </Button>
      </Container>
    </Wrapper>
  );
};

export default Login;
