import styled, { css } from "styled-components";

const Btn = styled.a`
  display: inline-block;
  background-color: #de8a33;
  background-image: linear-gradient(to right, #d43d5d, #de8a33);
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;
  transition: background-color 0.2s;
  text-decoration: none;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.7;
      pointer-events: none;
      cursor: not-allowed;
    `}
  //opacity: ${(props) => (props.isDisabled ? (props.href ? 1 : 0.2) : 1)};

  @media (max-width: 768px) {
    padding: 10px;
  }

  &:hover {
    background-color: #252525;
  }
`;

const Button = ({
  onClick,
  children,
  href,
  openTab,
  isDownloadable,
  isDisabled,
  downloadName,
}) => {
  return (
    <Btn
      href={href}
      target={openTab ? "blank" : ""}
      download={isDownloadable ? downloadName : ""}
      onClick={onClick}
      isDisabled={isDisabled}
    >
      {children}
    </Btn>
  );
};

export default Button;
