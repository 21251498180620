import gsap from "gsap";
import { SplitText } from "gsap/SplitText";
import debounce from "lodash/debounce";
import { v4 } from "uuid";

gsap.registerPlugin(SplitText);

const splitTextVars = {
  type: "lines",
};

const splitTextInstances = {};

let windowWidth = window.innerWidth;

window.addEventListener(
  "resize",
  debounce(() => {
    if (windowWidth !== window.innerWidth) {
      windowWidth = window.innerWidth;

      Object.keys(splitTextInstances).forEach((instanceId) => {
        const splitText = splitTextInstances[instanceId];
        splitText.split(splitTextVars);
      });
    }
  })
);

export const useSplitText = async (element) => {
  await document.fonts.ready;
  const splitText = new SplitText(element, splitTextVars);
  const id = v4();

  splitTextInstances[id] = splitText;

  const removeResizeListener = (id) => {
    delete splitTextInstances[id];
  };

  return {
    id,
    removeResizeListener,
    splitText,
  };
};
