import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Playground from "./pages/Playground";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HorizontalStories from "./components/HorizontalStories";
import ViewScroll from "./components/ViewScroll.js";
import NewHomepage from "./pages/NewHomepage.js";
import About from "./pages/About.jsx";
import About2 from "./pages/About2";
import Vision from "./pages/Vision.jsx";
import Founders from "./pages/Founders";
import Dashboard from "./pages/admin/Dashboard";
import Stories from "./components/admin/Stories";
import Users from "./components/admin/Users";
import EditStory from "./components/admin/EditStory";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NewHomepage />} />
        <Route path="/newhomepage" element={<NewHomepage />} />
        <Route path="/about" element={<About2 />} />
        <Route path="/about2" element={<About2 />} />
        <Route path="/vision" element={<Vision />} />
        <Route path="/founders" element={<Founders />} />
        <Route path="/galaxy" element={<ViewScroll />} />
        <Route path="/oldhomepage" element={<App />} />
        <Route path="/admin" element={<Dashboard />}>
          <Route path="/admin/stories" element={<Stories />} />
          <Route path="/admin/stories/:id" element={<EditStory />} />
          <Route path="/admin/users" element={<Users />} />
        </Route>
        <Route path="playground" element={<Playground />} />
        <Route path="horizontal" element={<HorizontalStories />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
