const Stories = [
  {
    id: 5,
    title: "Moersleutel",
    enTitle: "Wrench",
    videoSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654349930/stories/moersleutel_f28f9g.mp4",
    language: "en",
  },
  {
    id: 3,
    title: "Fiets",
    enTitle: "Bicycle",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098112/stories/Fiets_v2hkdl.m4a",
    language: "nl",
  },
  {
    id: 2,
    title: "Sigur Rós",
    enTitle: "Sigur Rós",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098111/stories/Sigur_Ro%CC%81s_y2srza.m4a",
    language: "nl",
  },
  {
    id: 1,
    title: "Vuurtoren",
    enTitle: "Lighthouse",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098112/stories/Vuurtorens_dqmcuf.m4a",
    language: "nl",
  },
  {
    id: 0,
    title: "Balkonplant",
    enTitle: "Balcony plant",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098114/stories/Balkonplant_kmkw7c.m4a",
    language: "nl",
  },
  {
    id: 4,
    title: "Zoon zoek",
    enTitle: "Son missing",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098111/stories/Zoon_zoek_vuy7dw.m4a",
    language: "nl",
  },
  {
    id: 6,
    title: "Champagne",
    enTitle: "Champagne",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098109/stories/Champagne_akjpee.m4a",
    language: "nl",
  },
  {
    id: 7,
    title: "Zwangerschap",
    enTitle: "Pregnancy",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098111/stories/Zwangerschap_xeehra.m4a",
    language: "nl",
  },
  {
    id: 8,
    title: "Grandfather",
    enTitle: "Grandfather",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654265242/stories/Accident_yaifhc.m4a",
    language: "en",
  },
];

export const dbStories = [
  {
    title: "Moersleutel",
    enTitle: "Wrench",
    // videoSrc:
    //   "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654349930/stories/moersleutel_f28f9g.mp4",
    videoSrc: {
      en: "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1655129758/stories/Moersleutel-EN_compressed_nj9xvh.mp4",
      nl: "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654937606/stories/Moersleutel-NL_compressed_rhkaj6.mp4",
    },
    id: "ab19b4d6-1a02-4d3f-8b3a-c5465a41a84c",
    approved: true,
    language: "en",
    videoPoster:
      "https://res.cloudinary.com/wonderfulworld-one/image/upload/v1654868282/wrench_poster_qqaetv.png",
  },
  {
    id: "a064e49d-55da-4de8-97f9-f85951f5d157",
    title: "Fiets",
    enTitle: "Bicycle",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098112/stories/Fiets_v2hkdl.m4a",
    approved: true,
    language: "nl",
  },
  {
    id: "2c64bc75-a83c-4a28-8043-729d82aa5bf0",
    title: "Sigur Rós",
    enTitle: "Sigur Rós",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098111/stories/Sigur_Ro%CC%81s_y2srza.m4a",
    approved: true,
    language: "nl",
  },
  {
    id: "3efa4a9f-5852-4145-bf7b-544331f1964f",
    title: "Vuurtoren",
    enTitle: "Lighthouse",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098112/stories/Vuurtorens_dqmcuf.m4a",
    approved: true,
    language: "nl",
  },
  {
    id: "85b0d06b-c2ea-45ab-9c9d-d5972e0444bc",
    title: "Balkonplant",
    enTitle: "Balcony plant",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098114/stories/Balkonplant_kmkw7c.m4a",
    approved: true,
    language: "nl",
  },
  {
    id: "f9a00d7c-cb9d-421a-ba1d-934e172070af",
    title: "Zoon zoek",
    enTitle: "Son missing",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654868639/stories/Zoon_zoek_z6hzgm.m4a",
    approved: true,
    language: "nl",
  },
  {
    id: "1b42a86c-d9a4-4c32-b1b4-7dfb532461da",
    title: "Champagne",
    enTitle: "Champagne",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098109/stories/Champagne_akjpee.m4a",
    approved: true,
    language: "nl",
  },
  {
    id: "4500d5c5-9f1d-423b-a726-512d9b99da2a",
    title: "Zwangerschap",
    enTitle: "Pregnancy",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654098111/stories/Zwangerschap_xeehra.m4a",
    approved: true,
    language: "nl",
  },
  {
    id: "ef5880a7-9ad2-448b-bedf-2fffe8d49819",
    title: "Grandfather",
    enTitle: "Grandfather",
    audioSrc:
      "https://res.cloudinary.com/wonderfulworld-one/video/upload/v1654265242/stories/Accident_yaifhc.m4a",
    approved: true,
    language: "en",
  },
];

export default Stories;
