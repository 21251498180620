import { socket } from "./socket";

export const get = async (event, external, options) => {
  if (external) {
    const response = await fetch(event, {
      method: "GET",
      ...options,
    });
    return response.json();
  } else {
    const response = new Promise((resolve, reject) => {
      socket.emit(event, (response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
    return response;
  }
};

// export const post = async (url, options, data) => {
//   const res = await fetch(url, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     ...options,
//     body: JSON.stringify(data),
//   });

//   if (res) {
//     return res.json();
//   } else {
//     throw Error("Something went wrong fetching...");
//   }
// };

export const post = async (event, data, external, options) => {
  if (external) {
    const response = await fetch(event, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      ...options,
    });
    if (response) {
      return response.json();
    } else {
      throw Error("Something went wrong fetching...");
    }
  } else {
    const response = new Promise((resolve, reject) => {
      socket.timeout(20000).emit(event, data, (err, response) => {
        if (err) {
          console.error(err);
          reject(
            Error(
              `Something went wrong with the socket emit, there is probably no event set up yet for: ${event}`
            )
          );
        } else {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            // console.log(response.error);
            reject(response.error);
          }
        }
      });
    });
    return response;
  }
};
