/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import Button from "./Button";
import SizedBox from "./SizedBox";
import Input from "./Input";
import { ReactComponent as Upload } from "../assets/icons/upload.svg";
import { ReactComponent as Mic } from "../assets/icons/mic.svg";
import copy from "../data/UploadCopy";
import { post } from "../modules/Fetch.js";
import { isProd } from "../modules/socket";

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const ContentContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  text-align: center;
  max-height: 100%;

  /* > div {
    margin: auto;
  } */
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;

  > div {
    &:first-child {
      margin-right: 80px;
    }

    &:last-child {
      margin-left: 80px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    > div {
      &:first-child {
        margin-top: 40px;
        margin-bottom: 40px;
        margin-right: 0;
      }

      &:last-child {
        margin-top: 40px;
        margin-left: 0;
      }
    }
  }
`;

const Divider = styled.div`
  width: 2px;
  background: white;

  @media screen and (max-width: 768px) {
    height: 2px;
    width: 80%;
    margin: auto;
  }
`;

const Heading = styled.h1`
  color: white;
`;

const UploadContainer = styled.div`
  text-align: center;

  input[type="text"] {
    display: block;
    margin: auto;
  }
`;

const FileUploadLabel = styled.label`
  display: flex;
  width: fit-content;
  align-items: center;
  // justify-content: space-between;
  margin: auto;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  padding: 10px 20px;
  border: 2px dashed #de8a33;
  transition: background-color 100ms;

  svg {
    width: 18px;
    margin-left: 10px;
  }

  &:hover {
    background-color: #8080805e;
  }
`;

const FileUpload = styled.input`
  margin-bottom: 10px;
  display: none;
`;

const ButtonContainer = styled.div`
  width: fit-content;
  margin: 30px auto 0 auto;
`;

const randomNumber = () => {
  return Math.floor(Math.random() * 1000);
};

const UploadPopup = ({ language, visible }) => {
  const uploadFileRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [filePresent, setFilePresent] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [fileName, setFileName] = useState("");
  const [uploaded, setIsuploaded] = useState(false);
  const [title, setTitle] = useState("");

  const checkInputFiles = () => {
    if (uploadFileRef.current?.files.length) {
      setFilePresent(true);
      setFileName(uploadFileRef.current.files[0].name);
    } else {
      setFilePresent(false);
      setFileName("");
    }
  };

  const uploadFile = async () => {
    if (isProd) {
      if (uploadFileRef.current?.files && emailInput) {
        setIsUploading(true);
        const file = uploadFileRef.current.files[0];
        const formData = new FormData();
        formData.append("upload_preset", "wonderfulworld_stories");
        formData.append(
          "public_id",
          `uploaded-recording-${randomNumber().toString()}-${emailInput}-${title}`
        );
        formData.append("file", file);
        const filePosted = await fetch(
          "https://api.cloudinary.com/v1_1/wonderfulworld-one/auto/upload",
          {
            method: "POST",
            body: formData,
          }
        ).then((res) => res.json());
        if (!filePosted.error) {
          setIsUploading(false);
          setEmailInput("");
          uploadFileRef.current.value = "";
          setFileName("");
          setFilePresent(false);
          setIsuploaded(true);
        }
      } else if (!emailInput) {
        alert("Email is niet ingevuld");
      }
    } else {
      if (uploadFileRef.current?.files && emailInput) {
        setIsUploading(true);
        const file = uploadFileRef.current.files[0];
        const { storyObj } = await post("uploadStory", {
          title,
          email: emailInput,
          file,
          preferredLanguage: language,
        });
        if (storyObj) {
          setIsUploading(false);
          setEmailInput("");
          uploadFileRef.current.value = "";
          setFileName("");
          setFilePresent(false);
          setIsuploaded(true);
        }
      }
    }
  };
  return (
    <ContentWrapper>
      <ContentContainer>
        <Flex>
          <div>
            <Heading>{copy[language].title}</Heading>
            <SizedBox />
            <UploadContainer>
              <FileUploadLabel>
                {filePresent ? (
                  fileName
                ) : (
                  <>
                    {copy[language].inputText} <Upload />
                  </>
                )}
                <FileUpload
                  type="file"
                  ref={uploadFileRef}
                  accept=".mp3,.mp4,.ogg,.m4a"
                  onChange={checkInputFiles}
                />
              </FileUploadLabel>
              <SizedBox height="40px" />
              <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                placeholder={copy[language].titleInput}
              />
              <SizedBox />
              <Input
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                type="email"
                placeholder="E-mail"
              />
              <SizedBox height="10px" />
              <ButtonContainer>
                <Button
                  loading={isUploading}
                  onClick={uploadFile}
                  isDisabled={!(filePresent && emailInput && title)}
                  disableAnimation
                >
                  {isUploading
                    ? "Uploading..."
                    : uploaded
                    ? copy[language].successMessage
                    : copy[language].cta}
                </Button>
              </ButtonContainer>
            </UploadContainer>
          </div>
          <Divider />
          <RecordComp language={language} />
        </Flex>
      </ContentContainer>
    </ContentWrapper>
  );
};

const RecordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RecordButton = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(145deg, #d43d5d, #de8a33);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 200ms;

  ${({ recording }) =>
    recording &&
    css`
      opacity: 0.5;
    `}

  &:hover {
    transform: scale(1.1);
  }

  svg {
    stroke: white;
  }
`;

const RecordComp = ({ language }) => {
  const [recording, setRecording] = useState(false);
  const [videoUrl, setVideoUrl] = useState(undefined);
  const [userEmail, setUserEmail] = useState("");
  const [audio64data, setAudio64Data] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");

  const onUploadRecording = async () => {
    const res = await post("uploadStory", {
      file: audio64data,
      title,
      email,
      isOwnRecording: true,
    });

    if (res.storyObj) {
      alert(copy[language].successMessage)
    }
  };

  return (
    <RecordContainer>
      <Heading>{copy[language].recordTitle}</Heading>
      <RecordButton
        onClick={() => {
          setRecording((prev) => !prev);
        }}
        recording={recording}
      >
        <Mic />
      </RecordButton>
      <RecordFunc
        recording={recording}
        onComplete={setVideoUrl}
        setAudio64Data={setAudio64Data}
      />
      <SizedBox height="10px" />
      <Input
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        type="text"
        placeholder={copy[language].titleInput}
      />
      <SizedBox />
      <Input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        placeholder="E-mail"
      />
      <SizedBox height="20px" />
      <Button
        onClick={onUploadRecording}
        disableAnimation
        isDisabled={recording || !audio64data || !title || !email}
      >
        Upload
      </Button>
      <SizedBox height="20px" />
    </RecordContainer>
  );
};

let mediaRecorder = null;

const startRecord = (stream, audioRef, onComplete, setAudio64Data) => {
  const audioChuncks = [];

  mediaRecorder = new MediaRecorder(stream);
  mediaRecorder.start();

  mediaRecorder.addEventListener("dataavailable", (event) => {
    audioChuncks.push(event.data);
  });

  mediaRecorder.addEventListener("stop", async () => {
    const audioBlob = new Blob(audioChuncks, { type: "audio/ogg" });
    const audioUrl = URL.createObjectURL(audioBlob);
    audioRef.current.src = audioUrl;
    onComplete(audioUrl);

    const reader = new FileReader();
    reader.readAsDataURL(audioBlob);
    reader.onloadend = async () => {
      const base64data = reader.result;
      setAudio64Data(base64data);
    };
    //   const audio = new Audio(audioUrl);
    //   audio.play()
    mediaRecorder = null;
  });
};

const stopRecording = () => {
  if (mediaRecorder) {
    mediaRecorder.stop();
  }
};

let interval;

const RecordFunc = ({ recording, onComplete, setAudio64Data }) => {
  const audioRef = useRef(null);
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (recording) {
      setTime(0);
      const beginStream = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        startRecord(stream, audioRef, onComplete, setAudio64Data);

        interval = setInterval(() => {
          setTime((prevTime) => prevTime + 1000);
        }, 1000);
      };
      beginStream();
    } else {
      stopRecording();
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording]);

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "5px",
        marginBottom: "5px",
        color: "white",
      }}
    >
      <p>
        <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
        <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
      </p>
      <audio ref={audioRef} controls></audio>
    </div>
  );
};

export default UploadPopup;
