import { useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import Colors from "./Colors";
import gsap from "gsap";
import Loader from "./Loader";

const ButtonComp = styled.button`
  // font-family: "Avenir Next";
  background-color: ${({ mode }) =>
    mode === "primary" ? Colors.primary : "white"};
  outline: none;
  border: none;
  padding: 12px 20px;
  font-size: ${({ size }) =>
    size === "m" ? "18px" : size === "s" ? "16px" : "18px"};
  border-radius: 5px;
  color: ${({ mode }) => (mode === "primary" ? "white" : "black")};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  transition: background-color 300ms;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.7;
      pointer-events: none;
      cursor: not-allowed;
    `}

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  > svg {
    width: 18px;
    height: auto;
    margin-left: 20px;

    path {
      stroke: ${({ mode }) => (mode === "primary" ? "white" : Colors.primary)};
    }
  }

  &:hover {
    background-color: ${({ mode }) =>
      mode === "primary" ? "#C54023" : "lightgray"};
  }
`;

const Button = ({
  onClick,
  children,
  mode = "primary",
  icon,
  disableAnimation = false,
  isDisabled = false,
  loading = false,
  ...props
}) => {
  const ButtonRef = useRef(null);

  useEffect(() => {
    if (ButtonRef.current && !disableAnimation) {
      const tl = gsap.timeline();

      tl.from(ButtonRef.current, {
        duration: 1,
        opacity: 0,
        y: 60,
        delay: 0.5,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ButtonComp
      isDisabled={isDisabled}
      ref={ButtonRef}
      mode={mode}
      onClick={onClick}
      size="m"
      {...props}
    >
      {loading ? (
        <Loader color={mode === "primary" ? "white" : "black"} />
      ) : (
        <>
          {children}
          {icon}
        </>
      )}
    </ButtonComp>
  );
};

export default Button;
