import { useState, useEffect } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import copy from "../data/AboutCopy";

const Heading = styled.h1`
  color: white;
  margin-top: 0;
`;

const Wrapper = styled.div`
  background: black;
  height: 100%;
  min-height: 100vh;
`;

const Container = styled.div`
  padding: 1em;
`;

const Vision = () => {
  const [language, setLanguage] = useState(localStorage.language || "en");

  useEffect(() => {
    localStorage.language = language;
  }, [language]);

  return (
    <Wrapper>
      <Header language={language} changeLanguage={setLanguage} />
      <Container>
        <Heading>{copy[language].aboutTitle}</Heading>
        {copy[language].about}
        <Heading>{copy[language].visionTitle}</Heading>
        {copy[language].vision}
      </Container>
    </Wrapper>
  );
};

export default Vision;
