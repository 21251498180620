const NavLinks = [
  {
    label: "About",
    to: "/about",
    // dropdown: [
    //   {
    //     label: "Vision",
    //     to: "/vision"
    //   },
    //   {
    //     label: "Founders",
    //     to: "/founders",
    //   }
    // ]
  },
  {
    label: "Stories",
    dropdown: [
      {
        label: "Discover",
        to: "/?enlarged=true",
      },
      {
        label: "Upload",
        to: "/?popup=upload",
      },
    ],
  },
  {
    label: "Language",
    dropdown: [
      {
        label: "Dutch",
        code: "nl",
      },
      {
        label: "English",
        code: "en",
      },
    ],
  },
];

const en = {
  NavLinks,
  getNotified: {
    buttonText: "Get notified",
    popupTitle: "Receive wonderful stories by mail",
    cta: "Subscribe",
    success: "Subscribed",
  },
};

const nlLinks = JSON.parse(JSON.stringify(NavLinks));
nlLinks[0].label = "Over ons";
// nlLinks[0].dropdown[0].label = "Ons verhaal";
// nlLinks[0].dropdown[1].label = "Oprichters";
nlLinks[1].label = "Verhalen";
nlLinks[1].dropdown[0].label = "Ontdekken";
nlLinks[1].dropdown[1].label = "Uploaden";
nlLinks[2].label = "Language";

const nl = {
  NavLinks: nlLinks,
  getNotified: {
    buttonText: "Mail mij",
    popupTitle: "Blijf op de hoogte van meer wonderlijke verhalen",
    cta: "Mail mij",
    success: "Doen we",
  },
};

const data = {
  en,
  nl,
};

export default data;
