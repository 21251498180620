import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Play } from "../assets/icons/play.svg";
import { ReactComponent as Pause } from "../assets/icons/pause.svg";
import { ReactComponent as ShareIcon } from "../assets/icons/share.svg";
import PlanetComp from "./Planet";
import WaveSurfer from "wavesurfer.js";
import Button from "./Button";
import SizedBox from "./SizedBox";
import Loader from "./Loader";
import copy from "../data/StoryPopupCopy";
import { useSearchParams } from "react-router-dom";
import { dbStories as Stories } from "../data/Stories";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Navigation } from "swiper";
import { get } from "../modules/Fetch.js";

import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  text-align: center;
  max-height: 100%;
  overflow-x: hidden;

  > div {
    margin: auto;
  }
`;

const PlanetContainer = styled.div`
  position: relative;
  width: ${({ planetSize }) => `${planetSize}px`};
  height: ${({ planetSize }) => `${planetSize}px`};
`;

const Planet = styled(PlanetComp)`
  cursor: unset;
  animation: rotate 5s infinite linear;
  animation-play-state: ${({ playing }) => (playing ? "running" : "paused")};

  svg {
    display: none;
  }

  @keyframes rotate {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

const WaveContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isVideo }) =>
    isVideo &&
    css`
      visibility: hidden;
    `}

  > span {
    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      margin-left: 20px;
    }
  }

  wave {
    overflow-x: hidden !important;
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Timing = styled.span`
  color: white;
`;

const Title = styled.h1`
  color: white;
  font-weight: 200;
  font-size: 54px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

const VideoWrapper = styled.div`
  width: 55%;
  margin: auto;
  @media screen and (max-width: 768px) {
    width: 75%;
  }
`;

const VideoContainer = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
`;

const VideoEl = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const PlayPauseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SwiperWrapper = styled.div`
  display: flex;
  visibility: visible;
  z-index: 5;
  width: 100%;
  // min-height: calc(100vh - 300px);
  align-items: center;
`;

const SwiperContainer = styled.div`
  width: 100%;
  max-height: 100%;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-pagination-fraction {
    color: white;
    /* top: ${({ activeIsVideo }) => (activeIsVideo ? "18vw" : "52%")};
    bottom: unset; */
    pointer-events: none;
  }

  .swiper-button-next {
    color: white;
  }

  .swiper-button-prev {
    color: white;
  }
`;

const StoryContainer = styled.div`
  width: 80%;
  margin: auto;

  > div {
    margin: auto;
  }
`;

const formatTime = (time) => {
  return [
    Math.floor((time % 3600) / 60), // minutes
    ("00" + Math.floor(time % 60)).slice(-2), // seconds
  ].join(":");
};

const StoryPopup = ({ language, planetSize = 300 }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const SwiperRef = useRef(null);
  const [activeIsVideo, setActiveIsVideo] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [stories, setStories] = useState([]);
  const [allStories, setAllStories] = useState([]);
  const [thisStory, setThisStory] = useState();
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobileByQuery = useMediaQuery({ query: "(max-width: 768px)" });
  let isMobile = false; //initiate as false
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\\-(n|u)|c55\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-|hi(pt|ta)|hp( i|ip)|hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\\cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\-|oo|p\\-)|sdk\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|tx\\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-|your|zeto|zte\\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    isMobile = true;
  }

  const getFilteredStories = (stories) => {
    let filteredStories = stories;
    if (language === "en") {
      filteredStories = filteredStories.filter(
        (story) => story.language === "en"
      );
    }
    return filteredStories;
  };

  useEffect(() => {
    const getStory = async () => {
      const res = await get("getApprovedStories");
      const allFetchedStories = res.stories;
      setAllStories(allFetchedStories);
      const filteredStories = getFilteredStories(allFetchedStories);
      setStories(filteredStories);

      if (!thisStory) {
        const { storyId } = params;
        const story = allFetchedStories.find((story) => story.id === storyId);
        if (storyId && story) {
          setThisStory(story);
          const storyInSwiperIndex = filteredStories.findIndex(
            (story) => story.id === storyId
          );
          if (storyInSwiperIndex >= 0) {
            setActiveIndex(storyInSwiperIndex);
            SwiperRef.current?.slideTo(storyInSwiperIndex);
          } else {
            const newStories = [...filteredStories, story];
            setStories(newStories);
            SwiperRef.current?.slideTo(newStories.length - 1);
          }
        } else {
          searchParams.set("storyId", filteredStories[0].id);
          setSearchParams(searchParams);
        }
      }
    };
    getStory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onIndexChange = (swiper) => {
    if (allStories.length > 0) {
      const activeIndex = swiper.activeIndex;
      setActiveIndex(activeIndex);
      const activeStoryId = stories[activeIndex]?.id;
      if (activeStoryId) {
        setThisStory(stories[activeIndex]);
        searchParams.set("storyId", activeStoryId);
        setSearchParams(searchParams);
      }
      const isVideo = !!allStories[activeIndex].videoSrc;
      setActiveIsVideo(isVideo);
    }
  };

  return (
    <ContentWrapper>
      <ContentContainer>
        {isDesktop && (
          <StoryContent
            planetSize={planetSize}
            storyId={thisStory?.id}
            language={language}
            isMobile={false}
            allStories={allStories}
          />
        )}
        {(isMobile || isMobileByQuery) && allStories.length > 0 && (
          <SwiperWrapper>
            <SwiperContainer activeIsVideo={activeIsVideo}>
              <Swiper
                modules={[Navigation]}
                navigation
                slidesPerView={1}
                onInit={(swiper) => {
                  onIndexChange(swiper);
                  SwiperRef.current = swiper;
                  swiper.slideTo(activeIndex);
                }}
                onActiveIndexChange={onIndexChange}
              >
                {stories.map((story, index) => (
                  <SwiperSlide key={index}>
                    <StoryContainer>
                      <StoryContent
                        planetSize={150}
                        storyId={story.id}
                        language={language}
                        index={index}
                        isMobile={true}
                        visible={thisStory?.id === story.id}
                        storiesLength={stories.length}
                        allStories={allStories}
                      />
                    </StoryContainer>
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperContainer>
          </SwiperWrapper>
        )}
      </ContentContainer>
    </ContentWrapper>
  );
};

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > div:first-child {
    margin-right: 30px;
  }

  svg {
    color: white;
  }
`;

const ShareContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: none;
  transition: background-color 200ms;

  &:hover {
    background-color: rgba(107, 107, 107, 0.3);
  }
`;

export const StoryContent = ({
  planetSize,
  language,
  index = 0,
  storyId,
  isMobile,
  visible,
  storiesLength,
  allStories,
}) => {
  const [thisStory, setThisStory] = useState();
  const videoRef = useRef(null);
  const [durationTime, setDurationTime] = useState("00:00");
  const [playbackTime, setPlaybackTime] = useState("00:00");
  const [waveIsReady, setWaveIsReady] = useState(false);
  const waveInitiated = useRef(false);
  const wavesurferInstance = useRef(null);
  const [audioPlaying, setAudioPlaying] = useState(false);

  const onPlay = () => {
    setAudioPlaying(true);
    wavesurferInstance?.current?.play();
  };

  const onPause = () => {
    setAudioPlaying(false);
    wavesurferInstance?.current?.pause();
  };

  const onShare = () => {
    if (navigator.share) {
      try {
        navigator.share({
          title: thisStory?.title,
          url: document.location.href,
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      if (navigator.clipboard) {
        try {
          navigator.clipboard.writeText(document.location.href);
          console.log(document.location.href);
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log("Clipboard is not supported");
      }
    }
  };

  useEffect(() => {
    if (storyId) {
      setThisStory(allStories.find((story) => story.id === storyId));
    }
  }, [storyId]);

  useEffect(() => {
    const checkAudio = async () => {
      if (thisStory) {
        if (thisStory.audioSrc) {
          const audioData = await fetch(thisStory.audioSrc)
            .then((res) => res.arrayBuffer())
            .then((data) => data);
          const audioblob = new Blob([audioData]);
          if (!wavesurferInstance.current) {
            const wavesurfer = WaveSurfer.create({
              container: `.waveform-${index}`,
              waveColor: "gray",
              progressColor: "white",
              responsive: true,
            });
            waveInitiated.current = true;
            wavesurferInstance.current = wavesurfer;
            wavesurfer.loadBlob(audioblob);
            wavesurfer.on("ready", () => {
              setWaveIsReady(true);
              setDurationTime(formatTime(wavesurfer.getDuration()));
            });
            wavesurfer.on("audioprocess", () => {
              setPlaybackTime(formatTime(wavesurfer.getCurrentTime()));
            });
          } else {
            // const wavesurfer = WaveSurfer.create({
            //   container: ".waveform",
            //   waveColor: "gray",
            //   progressColor: "white",
            // });
            // waveInitiated.current = true;
            // wavesurferInstance.current = wavesurfer;
            // wavesurfer.load(currentStory.audioSrc);
            // wavesurfer.on("ready", () => {
            //   setWaveIsReady(true);
            // });
          }
        }
      }
    };
    checkAudio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisStory]);

  useEffect(() => {
    return () => {
      if (wavesurferInstance.current) {
        wavesurferInstance.current.pause();
        setAudioPlaying(false);
      }
      if (videoRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoRef.current.pause();
      }
    };
  }, []);

  useEffect(() => {
    if (!visible) {
      if (wavesurferInstance.current) {
        wavesurferInstance.current.pause();
        setAudioPlaying(false);
      }
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  }, [visible]);

  return (
    <>
      {thisStory && (
        <>
          {!thisStory.videoSrc && (
            <PlanetContainer planetSize={planetSize}>
              <Planet
                playing={audioPlaying}
                size={planetSize}
                audioSrc={true}
                story={thisStory}
              />
            </PlanetContainer>
          )}
          <SizedBox height="23px" />
          <Title>
            {language === "en" ? thisStory?.enTitle : thisStory?.title}
          </Title>
          {isMobile && (
            <p style={{ color: "white" }}>
              {index + 1} / {storiesLength}
            </p>
          )}
          {thisStory.videoSrc && (
            <VideoWrapper>
              <VideoContainer>
                <VideoEl
                  poster={thisStory.videoPoster}
                  controls
                  ref={videoRef}
                  src={thisStory.videoSrc[language] || thisStory.videoSrc}
                />
              </VideoContainer>
            </VideoWrapper>
          )}
          <WaveContainer isVideo={thisStory.videoSrc}>
            <Timing>{playbackTime}</Timing>
            {!waveIsReady && (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            )}
            <div
              style={{
                width: "500px",
              }}
              className={`waveform-${index}`}
            />
            <Timing>{durationTime}</Timing>
          </WaveContainer>
          <SizedBox />
          <ActionsContainer>
            {thisStory.audioSrc && (
              <PlayPauseContainer>
                {audioPlaying ? (
                  <Button
                    isDisabled={!waveIsReady}
                    disableAnimation
                    icon={<Pause />}
                    onClick={onPause}
                  >
                    {copy[language].ctaPause}
                  </Button>
                ) : (
                  <Button
                    isDisabled={!waveIsReady}
                    disableAnimation
                    icon={<Play />}
                    onClick={onPlay}
                  >
                    {copy[language].ctaListen}
                  </Button>
                )}
              </PlayPauseContainer>
            )}
            <ShareContainer onClick={onShare}>
              <ShareIcon />
            </ShareContainer>
          </ActionsContainer>
          <SizedBox />
        </>
      )}
    </>
  );
};

export default StoryPopup;
