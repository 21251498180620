import { useEffect, useState } from "react";
import styled from "styled-components";
import { get } from "../../modules/Fetch";
import Login from "./Login";
import SizedBox from "../../components/SizedBox";
import Sidebar from "../../components/admin/Sidebar";
import { Outlet, useLocation } from "react-router-dom";
import Button from "../../components/Button";

const Wrapper = styled.div`
  // background: black;
  // padding: 10px;
`;

const Heading = styled.h1`
  // color: white;
`;
const ContentContainer = styled.div`
  margin-left: 260px;
  padding: 10px;
  // background: #f8f8f8;
`;

const Dashboard = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [rssIsLoading, setRssIsLoading] = useState(false);
  let location = useLocation();

  const onGenerateRssFeed = async () => {
    setRssIsLoading(true);
    const { status } = await get("generateRssFeed");
    setRssIsLoading(false);
    if (status === 200) {
      alert("Updated! Het kan even duren voordat Spotify het heeft geupdate");
    } else {
      alert("Niet gelukt!");
    }
  };

  useEffect(() => {
    const checkLoggedIn = async () => {
      const { user } = await get("authenticate");
      setIsLoggedIn(!!user);
      // console.log(user);
      setUser(user);
    };
    checkLoggedIn();
  }, []);

  useEffect(() => {
    const checkUser = async () => {
      if (isLoggedIn) {
        if (!user) {
        }
        // navigate("/dashboard");
      } else {
        setUser(null);
      }
    };

    checkUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <Wrapper>
      {!isLoggedIn && <Login setUser={setUser} setIsLoggedIn={setIsLoggedIn} />}
      {isLoggedIn && user && (
        <>
          <Sidebar user={user} />
          <ContentContainer>
            {location.pathname === "/admin" && (
              <>
                <Heading>Welkom {user.username}!</Heading>
                <Button
                  loading={rssIsLoading}
                  onClick={onGenerateRssFeed}
                  disableAnimation
                >
                  Update RSS Feed voor podcast
                </Button>
              </>
            )}
            <Outlet />
            <SizedBox height="100px" />
          </ContentContainer>
        </>
      )}
    </Wrapper>
  );
};

export default Dashboard;
