import { useEffect, useRef } from "react";
import styled from "styled-components";
import galaxyImg from "../images/galaxy.jpg";

const AllView = styled.div`
  height: 100vh;
  overflow: hidden;
  /* position: relative; */
  /* background-image: url("");
  background-size: cover;
  background-repeat: no-repeat; */
`;

const Viewport = styled.div`
  width: 1000px;
  height: 650px;
  width: 90%;
  height: 90%;
  border: 2px solid grey;
  overflow: scroll;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:hover {
    cursor: grab;
  }
`;

const Img = styled.img`
  // transform: scale(0.7);
`;

const Planets = [
  {
    title: "Vuurtoren",
    x: 20,
    y: 60,
  },
  {
    title: "Balkonplant",
    x: 40,
    y: 40,
  },
  {
    title: "Balkonplant",
    x: 70,
    y: 40,
  },
];

const Planet = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-image: linear-gradient(to right, #d43d5d, #de8a33);
  position: absolute;
  top: ${({ y }) => y}%;
  left: ${({ x }) => x}%;
  z-index: 2;
`;

const ViewScroll = () => {
  const imageGrabbing = useRef(false);
  const viewPortRef = useRef(null);
  let scrollImagePos = { top: 0, left: 0, x: 0, y: 0 };

  const pointerMoveHandler = (event) => {
    if (imageGrabbing.current) {
      const deltaX = event.clientX - scrollImagePos.x;
      const deltaY = event.clientY - scrollImagePos.y;

      if (viewPortRef.current) {
        viewPortRef.current.scrollLeft = scrollImagePos.left - deltaX;
        viewPortRef.current.scrollTop = scrollImagePos.top - deltaY;
      }
    }
  };

  const pointerUpHandler = () => {
    imageGrabbing.current = false;

    if (viewPortRef.current) {
      viewPortRef.current.style.cursor = "";
    }
  };

  const pointerDownHandler = (event) => {
    imageGrabbing.current = true;

    if (viewPortRef.current) {
      viewPortRef.current.style.cursor = "grabbing";
      scrollImagePos = {
        left: viewPortRef.current.scrollLeft,
        top: viewPortRef.current.scrollTop,
        x: event.clientX,
        y: event.clientY,
      };
    }
  };

  useEffect(() => {
    if (viewPortRef.current) {
      viewPortRef.current.scrollLeft = viewPortRef.current.offsetWidth / 2;
    }
  }, []);
  return (
    <AllView>
      <Viewport
        onPointerMove={pointerMoveHandler}
        onPointerUp={pointerUpHandler}
        onPointerDown={pointerDownHandler}
        ref={viewPortRef}
      >
        <Img draggable={false} src={galaxyImg} />
        {Planets.map((pl) => (
          <Planet y={pl.y} x={pl.x} />
        ))}
        {/* <InnerArt /> */}
      </Viewport>
    </AllView>
  );
};

export default ViewScroll;
