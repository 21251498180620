import styled from "styled-components";
import { Link as LinkComp } from "react-router-dom";

const Wrapper = styled.div``;

const HeaderItem = styled.span`
  font-weight: 600;
`;

const Link = styled(LinkComp)`
  color: unset;
  text-decoration: none;
`;

const Row = styled.div`
  display: flex;
  gap: 40px;
  min-height: 50px;
  border-top: 1px solid #dadada;
  align-items: center;
  padding: 0 5px;
  // justify-content: space-around;

  span {
    color: #8a8a8a;
    // max-width: 120px;
    width: 100%;
    min-width: 100px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    background: #fafafa;
  }
`;

const Header = styled(Row)`
  background: #fafafa;

  span {
    /* overflow-x: unset;
    white-space: wrap; */
  }
`;

const RowItem = styled.span``;

const Table = ({ columns, items, areStories }) => {
  items = items.map((item) => {
    const keys = Object.keys(item);
    keys.map((key) => {
      item[key] = item[key].toString();
      return null;
    });
    return item;
  });

  return (
    <Wrapper>
      <Header>
        {columns.map((column, i) => (
          <HeaderItem key={`headerItem-${i}`}>{column.label}</HeaderItem>
        ))}
      </Header>
      {items.map((item, i) => (
        <Link key={`row-${i}`} to={areStories ? `/admin/stories/${item.id}` : "/admin/"}>
          <Row>
            {columns.map((column, index) => (
              <RowItem key={`rowItem-${index}`}>
                {item[column.key]?.includes("Src") ? (
                  <a href={item[column.key]}>{item[column.key]}</a>
                ) : (
                  item[column.key]
                )}
              </RowItem>
            ))}
          </Row>
        </Link>
      ))}
    </Wrapper>
  );
};

export default Table;
