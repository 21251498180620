/* eslint-disable no-unused-vars */
import { useState } from "react";
import styled from "styled-components";
//import Title from "../components/Title";
import Button from "../components/old/Button";
//import RecordVideo from "../components/RecordVideo";
// import RecordAudio from "../components/RecordAudio";
import StaticContent from "../components/StaticContent";
import Modal from "../components/Modal";
import RecordAudioModalContent from "../components/RecordAudioModalContent";
import ListenStoriesModalContent from "../components/ListenStoriesModalContent";
import BackgroundImg from "../images/after.jpg";

const Wrapper = styled.div`
  font-family: "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  position: relative;
  min-height: 100vh;
  padding-top: 5vh;

  p {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    background-position: center center;

    p {
      font-size: 16px;
    }
  }
`;

const TransparencyBg = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  padding: 30px;
  width: fit-content;
  /* margin-top: 5vh; */
  margin-left: 10vw;
  max-width: 380px;
  position: relative;

  @media (max-width: 768px) {
    margin: auto;
    //margin-top: 80px;
    padding: 30px;
  }
`;

const Home = () => {
  const [recordModalOpen, setRecordModalOpen] = useState(false);
  const [recording, setRecording] = useState(false);
  const [videoUrl, setVideoUrl] = useState(undefined);
  const [fileName, setFileName] = useState("Wonder_recording");
  const [listenStoriesModal, setListenStoriesModal] = useState(false);
  const [language, setLanguage] = useState("nl");

  return (
    <Wrapper>
      <TransparencyBg>
        <div style={{ position: "absolute", right: "50px", cursor: "pointer" }}>
          {language !== "nl" && (
            <span onClick={() => setLanguage("nl")}>🇳🇱</span>
          )}
          {language !== "en" && (
            <span onClick={() => setLanguage("en")}>🇬🇧</span>
          )}
        </div>
        <StaticContent language={language} />
        <div
          style={{
            display: "flex",
            gap: "15px",
            margin: "30px 0",
          }}
        >
          <Button
            onClick={() => {
              setListenStoriesModal(true);
              document.body.style.overflowY = "hidden";
            }}
          >
            {language === "en" ? "Listen to stories" : "Luisteren"}
          </Button>
          <Button
            onClick={() => {
              setRecordModalOpen(true);
              document.body.style.overflowY = "hidden";
            }}
          >
            {language === "en" ? "Record your story" : "Opnemen"}
          </Button>
        </div>
        {language === "en" ? (
          <p>
            Do you feel that our world is full of magical
            <br />
            experiences? Most of us do. And although they often light up our
            life, they are not given a platform to inspire everyday
            conversation.
            <br />
            Though they are very impressive and
            <br />
            sometimes change the way we give meaning to our life forever. What
            if there’s more to life than we perceive? What if we could share in
            these experiences and in so doing change our perception of reality
            for the better?
          </p>
        ) : language === "nl" ? (
          <p>
            Heb jij het gevoel dat onze wereld vol zit met magische ervaringen?
            De meeste mensen wel. Toch zijn ze vaak niet het onderwerp van ons
            gesprek, laat staan dat ze een platform krijgen.
            <br />
            Wonderen zijn vaak indrukwekkend en hebben de kracht om iemands kijk
            op het leven totaal veranderen. Wat als er meer in het leven is dan
            we kunnen waarnemen? Wat als we onze wonderen met elkaar delen en
            daarmee onze ervaring van het leven op een positievere manier kunnen
            veranderen?
          </p>
        ) : (
          ""
        )}
        <Modal
          open={listenStoriesModal}
          closeModal={() => {
            setListenStoriesModal(false);
            document.body.style.overflowY = "";
          }}
        >
          <ListenStoriesModalContent language={language} />
        </Modal>
        <Modal
          open={recordModalOpen}
          closeModal={() => {
            setRecordModalOpen(false);
            document.body.style.overflowY = "";
          }}
        >
          <RecordAudioModalContent language={language} />
        </Modal>
      </TransparencyBg>
      {/* <RecordVideo onComplete={setVideoUrl} setFileName={setFileName} recording={recording} /> */}
      <div style={{ display: "flex", justifyContent: "center" }}></div>
    </Wrapper>
  );
};

export default Home;
