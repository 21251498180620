import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { post } from "../../modules/Fetch";
import styled, { css } from "styled-components";
import SizedBox from "../SizedBox";
import Button from "../Button.jsx";
import Popup from "./Popup";
import Colors from "../Colors";

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    width: 100%;
  }
`;

const StoryTitle = styled.h1`
  color: #464646;
  font-weight: 500;
  font-size: 36px;
  margin-bottom: 0;
`;

const HelperText = styled.span`
  color: #5a5a5a;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.7;
  background: #eee;
  margin: 20px auto;
`;

const RowsContainer = styled.div`
  width: 95%;
  margin: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  > * {
    width: 100%;
  }
`;

const RowLabel = styled.p`
  color: #5a5a5a;
`;

const InputsContainer = styled.div``;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: #5a5a5a;
`;

const Input = styled.input`
  max-width: 250px;
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 5px;
`;

const Textarea = styled.textarea`
  font-family: "Avenir next";
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 5px;
`;

const Video = styled.video`
  width: 300px;
`;

const EditStory = () => {
  const [story, setStory] = useState({});
  const [originalStory, setOriginalStory] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  let params = useParams();

  const { id } = params;

  const onChangeInput = (value, key) => {
    let updatedValue = { [key]: value };
    setStory((prevStory) => ({
      ...prevStory,
      ...updatedValue,
    }));
  };

  const onSaveStory = async () => {
    setIsSaving(true);
    const cloneStory = story;
    if (!originalStory.approved && cloneStory.approved) {
      cloneStory.isNewApproved = true;
    }
    const { savedStory } = await post("saveStory", cloneStory);
    if (savedStory) {
      setIsSaving(false);
      alert("Saved!");
      if (!originalStory.approved && savedStory.approved) {
        setShowEmailPopup(true);
      }
      setOriginalStory(savedStory);
    }
  };

  const onRemoveStory = async () => {
    const { removed } = await post("removeStory", story);
    if (removed) {
      alert("Is verwijderd!");
    } else {
      alert("Verwijderen is niet gelukt");
    }
  };

  const onSendEmail = async () => {
    const { sent } = await post("sendEmails", {
      mailToSubscribers: false,
      mailToUser: true,
      confirmationToUser: false,
      story,
    });
    console.log(sent);
    alert(sent);
  };

  useEffect(() => {
    const getStory = async () => {
      const { story } = await post("getStory", {
        id,
      });
      if (story) {
        setStory(story);
        setOriginalStory(story);
      }
    };
    getStory();

    // setTimeout(() => {
    //   setShowEmailPopup(true);

    //   // setTimeout(() => {
    //   //   setShowEmailPopup(false);
    //   // }, 2000);
    // }, 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {story && (
        <>
          <Popup show={showEmailPopup}>
            <EmailsPopupContent
              onClose={() => setShowEmailPopup(false)}
              userEmail={story.userEmail}
              story={story}
            />
          </Popup>

          <TopContainer>
            <div>
              <StoryTitle>
                {story.title} - ({story.enTitle})
              </StoryTitle>
              <HelperText>Pas hier de data van het verhaal aan.</HelperText>
            </div>
            <div>
              <Button loading={isSaving} onClick={onSaveStory} disableAnimation>
                Sla op
              </Button>
            </div>
          </TopContainer>
          <Divider />
          <RowsContainer>
            <Row>
              <RowLabel>Details</RowLabel>
              <InputsContainer>
                <Label>
                  ID
                  <Input
                    value={story.id || ""}
                    disabled
                    onChange={(e) => onChangeInput(e.target.value, "id")}
                  />
                </Label>
                <SizedBox />
                <Label>
                  Titel
                  <Input
                    value={story.title || ""}
                    onChange={(e) => onChangeInput(e.target.value, "title")}
                  />
                </Label>
                <SizedBox />
                <Label>
                  Engelse Titel
                  <Input
                    value={story.enTitle || ""}
                    onChange={(e) => onChangeInput(e.target.value, "enTitle")}
                  />
                </Label>
                <SizedBox />
                <Label>
                  Beschrijving
                  <Textarea
                    value={story.description || ""}
                    onChange={(e) =>
                      onChangeInput(e.target.value, "description")
                    }
                  />
                </Label>
                <SizedBox />
                <Label>Goedgekeurd</Label>
                <Switch
                  value={story.approved || false}
                  onchange={(value) => onChangeInput(value, "approved")}
                />
              </InputsContainer>
            </Row>
            <Divider />
            <Row>
              <RowLabel>Gebruiker</RowLabel>
              <InputsContainer>
                <Label>
                  Email
                  <Input
                    value={story.userEmail || ""}
                    onChange={(e) => onChangeInput(e.target.value, "userEmail")}
                  />
                </Label>
                <SizedBox />
                <Label>
                  Gebruiker Identifier
                  <Input
                    value={story.userId || ""}
                    disabled
                    onChange={(e) => onChangeInput(e.target.value, "userId")}
                  />
                </Label>
              </InputsContainer>
            </Row>
            <Divider />
            <Row>
              <RowLabel>Technisch</RowLabel>
              <InputsContainer>
                <Label>
                  Bestand
                  <Input
                    value={story.audioSrc || story.videoSrc || ""}
                    disabled
                    onChange={(e) =>
                      onChangeInput(
                        e.target.value,
                        story.audioSrc ? "audioSrc" : "videoSrc"
                      )
                    }
                  />
                </Label>
                <SizedBox />
                {story.audioSrc && <audio src={story.audioSrc} controls />}
                {story.videoSrc && <Video src={story.videoSrc} controls />}
              </InputsContainer>
            </Row>
          </RowsContainer>
          <Button onClick={onSendEmail} disableAnimation>
            Stuur bericht (Test!)
          </Button>
          <SizedBox />
          <Button onClick={onRemoveStory} disableAnimation>
            Verwijder verhaal
          </Button>
        </>
      )}
    </div>
  );
};

const ButtonsContainer = styled.div`
  display: flex;

  > button:first-child {
    margin-right: 20px;
  }
`;

const SecondaryButton = styled(Button)`
  border: 1px solid ${Colors.primary};
  color: ${Colors.primary};
`;

const EmailsPopupContent = ({ onClose, userEmail, story }) => {
  const [choices, setChoices] = useState({
    mailToSubscribers: false,
    mailToUser: false,
  });

  const onchange = (value, key) => {
    const newValue = { [key]: value };
    setChoices((prev) => ({
      ...prev,
      ...newValue,
    }));
  };

  const onSendMail = async () => {
    const { sent } = await post("sendEmails", {
      mailToSubscribers: choices.mailToSubscribers,
      mailToUser: choices.mailToUser,
      story,
    });
    if (sent) {
      console.log(sent);
      alert("Verstuurd!");
      onClose();
    }
  };

  return (
    <div>
      <h2>Emails</h2>
      <Divider style={{ marginTop: 0 }} />
      <label>
        <input
          checked={choices.mailToSubscribers}
          onChange={(e) => onchange(e.target.checked, "mailToSubscribers")}
          type="checkbox"
        />
        Stuur email naar alle geabboneerden
      </label>
      <SizedBox />
      <label>
        <input
          checked={choices.mailToUser}
          onChange={(e) => onchange(e.target.checked, "mailToUser")}
          type="checkbox"
        />
        Stuur email naar {userEmail}
      </label>
      <SizedBox />
      <ButtonsContainer>
        <SecondaryButton onClick={onClose} mode="secondary" disableAnimation>
          Annuleer
        </SecondaryButton>
        <Button
          isDisabled={!choices.mailToUser && !choices.mailToSubscribers}
          disableAnimation
          onClick={onSendMail}
        >
          Stuur email
        </Button>
      </ButtonsContainer>
    </div>
  );
};

const SwitchComp = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 5px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  ${({ checked }) =>
    checked &&
    css`
      background-color: #2196f3;
    `}
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    ${({ checked }) =>
      checked &&
      css`
        transform: translateX(26px);
      `}
  }

  ${({ checked }) =>
    checked &&
    css`
      background-color: #2196f3;
    `}
`;

const Switch = ({ value, key, onchange }) => {
  return (
    <SwitchComp>
      <SwitchInput
        checked={value}
        onChange={(e) => {
          onchange(e.target.checked);
        }}
        type="checkbox"
      />
      <Slider checked={value} />
    </SwitchComp>
  );
};

export default EditStory;
