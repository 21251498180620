import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import LogoTrans from "../images/logo_transparant.png";
import { Link } from "react-router-dom";
import { ReactComponent as Menu } from "../assets/icons/menu.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron-down.svg";
import { ReactComponent as Instagram } from "../assets/icons/instagram.svg";
import { ReactComponent as Spotify } from "../assets/icons/spotify.svg";
import Button from "./Button";
import GetNotifiedPopup from "./GetNotifiedPopup";
import copy from "../data/HeaderCopy";
import SizedBox from "./SizedBox";

const HeaderComp = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 60px 0 60px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  @media screen and (max-width: 768px) {
    padding-top: 25px;
  }
`;

const Logo = styled.img`
  width: 200px;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

  /* Because Safari(<=14) doesn't support gap */
  > * {
    margin: 0 15px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const HamburgerMenu = styled.div`
  cursor: pointer;
  @media screen and (min-width: 768px) {
    display: none;
  }

  svg {
    stroke: white;
  }
`;

const Sidebar = styled.div`
  position: fixed;
  z-index: 15;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const CloseContainer = styled.div`
  position: absolute;
  top: 100px;
  right: 60px;
  cursor: pointer;

  svg {
    stroke: white;
  }
`;

const SidebarNav = styled.nav`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > a {
    margin-bottom: 2em;
    display: block;
  }
`;

const SidebarDropdown = styled.p`
  color: white;
  font-size: 18px;
  display: flex;
  cursor: pointer;

  > svg {
    margin-left: 6px;
  }
`;

const SidebarDropdownContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  > * {
    margin: 5px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SocialLink = styled.a`
  color: white;
  text-align: center;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 18px;
`;

const LanguageLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 18px;
  background: ${({ active }) => (active ? "#4a4a4a" : "unset")};
  cursor: pointer;

  @media screen and (max-width: 768px) {
    background: unset;
    font-weight: ${({ active }) => (active ? 700 : "unset")};
  }
`;

const DropdownContainer = styled.div`
  position: relative;

  &:hover {
    div {
      visibility: visible;
    }
  }
`;

const DropdownLabel = styled.span`
  font-size: 18px;
  color: white;
  cursor: default;
`;

const DropdownContent = styled.div`
  position: absolute;
  background: #2b2b2b;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

  a {
    padding: 15px 10px;
    color: white;

    &:hover {
      background: #4a4a4a;
    }
  }
`;

const Header = ({ language, changeLanguage }) => {
  const [menuIsCollapsed, setMenuIsCollapsed] = useState(true);
  const SidebarRef = useRef(null);
  const DropdownLinks = copy[language].NavLinks.filter(
    (entry) => entry.dropdown
  );
  const [DropdownsState, setDropdownsState] = useState(
    DropdownLinks.map(() => false)
  );
  const DropdownRefs = useRef(DropdownLinks.map(() => null));
  const [showNotifiedPopup, setNotifiedPopup] = useState(false);

  useEffect(() => {
    gsap.set(SidebarRef.current, {
      x: "100%",
    });
  }, []);

  const toggleDropdown = (index) => {
    const prevState = [...DropdownsState];
    prevState[index] = !prevState[index];
    setDropdownsState(prevState);
  };

  useEffect(() => {
    DropdownsState.map((dropdown, index) => {
      const tl = gsap.timeline({ paused: true });
      tl.fromTo(
        DropdownRefs.current[index],
        {
          maxHeight: 0,
        },
        {
          maxHeight: "fit-content",
        }
      );
      if (dropdown) {
        tl.play();
      } else {
        tl.reverse();
      }
      return null;
    });
  }, [DropdownsState]);

  const SlideOut = () => {
    gsap.to(SidebarRef.current, {
      x: "100%",
    });
  };

  useEffect(() => {
    if (!menuIsCollapsed) {
      gsap.to(SidebarRef.current, {
        x: 0,
      });
    }
  }, [menuIsCollapsed]);

  return (
    <HeaderComp>
      <GetNotifiedPopup
        language={language}
        onClose={() => setNotifiedPopup(false)}
        show={showNotifiedPopup}
      />
      <NavLink to="/">
        <Logo src={LogoTrans} />
      </NavLink>
      <Nav>
        <div>
          <SocialLink
            href="https://www.instagram.com/wonderfulworld.one/"
            target="_blank"
            style={{ marginRight: "5px" }}
          >
            <Instagram />
          </SocialLink>
          <SocialLink
            href="https://open.spotify.com/show/2GvRjHjMim6J7NRo7XxRfO?si=3537eaf5c2f44c4a"
            target="_blank"
          >
            <Spotify />
          </SocialLink>
        </div>
        {copy[language].NavLinks.map((entry, index) => {
          return entry.to ? (
            <NavLink key={index} to={entry.to}>
              {entry.label}
            </NavLink>
          ) : (
            <DropdownContainer key={index}>
              <DropdownLabel>{entry.label}</DropdownLabel>
              <DropdownContent>
                {entry.dropdown.map((dropdownEntry, index) =>
                  dropdownEntry.code ? (
                    <LanguageLink
                      key={index}
                      onClick={() => changeLanguage(dropdownEntry.code)}
                      active={language === dropdownEntry.code}
                    >
                      {dropdownEntry.label}
                    </LanguageLink>
                  ) : (
                    <NavLink key={index} to={dropdownEntry.to}>
                      {dropdownEntry.label}
                    </NavLink>
                  )
                )}
              </DropdownContent>
            </DropdownContainer>
          );
        })}
        <Button
          onClick={() => setNotifiedPopup(true)}
          mode="secondary"
          disableAnimation
        >
          {copy[language].getNotified.buttonText}
        </Button>
      </Nav>
      <HamburgerMenu onClick={() => setMenuIsCollapsed(false)}>
        <Menu />
      </HamburgerMenu>
      <Sidebar ref={SidebarRef} collapsed={menuIsCollapsed}>
        <CloseContainer
          onClick={() => {
            SlideOut();
            setMenuIsCollapsed(true);
          }}
        >
          <Close />
        </CloseContainer>
        <SidebarNav>
          {copy[language].NavLinks.map((entry, index) => {
            return (
              entry.to && (
                <NavLink key={index} to={entry.to}>
                  {entry.label}
                </NavLink>
              )
            );
          })}
          {DropdownLinks.map((entry, index) => (
            <div key={index}>
              <SidebarDropdown onClick={() => toggleDropdown(index)}>
                {entry.label} <ChevronDown />
              </SidebarDropdown>
              <SidebarDropdownContent
                ref={(el) => (DropdownRefs.current[index] = el)}
              >
                {entry.dropdown.map((dropdownEntry, dropdownIndex) =>
                  dropdownEntry.code ? (
                    <LanguageLink
                      key={dropdownIndex}
                      onClick={() => changeLanguage(dropdownEntry.code)}
                      active={language === dropdownEntry.code}
                    >
                      {dropdownEntry.label}
                    </LanguageLink>
                  ) : (
                    <NavLink key={dropdownIndex} to={dropdownEntry.to}>
                      {dropdownEntry.label}
                    </NavLink>
                  )
                )}
              </SidebarDropdownContent>
            </div>
          ))}
          <SizedBox />
          <Button
            onClick={() => setNotifiedPopup(true)}
            mode="secondary"
            disableAnimation
            style={{ padding: "14px 28px" }}
          >
            {copy[language].getNotified.buttonText}
          </Button>
          <SizedBox />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SocialLink
              href="https://www.instagram.com/wonderfulworld.one/"
              target="_blank"
              style={{ marginRight: "5px" }}
            >
              <Instagram />
            </SocialLink>
            <SocialLink
              href="https://open.spotify.com/show/2GvRjHjMim6J7NRo7XxRfO?si=3537eaf5c2f44c4a"
              target="_blank"
            >
              <Spotify />
            </SocialLink>
          </div>
        </SidebarNav>
      </Sidebar>
    </HeaderComp>
  );
};

export default Header;
