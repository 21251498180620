import { useEffect } from "react";
import styled from "styled-components";
import ViewScroll from "../components/ViewScroll";
import HorizontalStories from "../components/HorizontalStories";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import StoryPopup, { StoryContent } from "../components/StoryPopup";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: black;
`;

const ViewContainer = styled.div`
  width: 1000px;
  height: 700px;
  border: 2px solid red;
  overflow: auto;
  margin: 50px 0;
`;

const Playground = () => {
  useEffect(() => {
    console.log("mounted");
  }, []);

  return (
    <Wrapper>
      {/* <ViewScroll />
      <ViewContainer>
        <HorizontalStories />
      </ViewContainer> */}
      <StoryPopup language="nl" planetSize={150} />
      {/* <Swiper>
        <SwiperSlide>
          <div style={{ width: "90%" }}>
            <StoryContent
              planetSize={150}
              language="nl"
              index={0}
              storyId="ab19b4d6-1a02-4d3f-8b3a-c5465a41a84c"
              isMobile
              visible
              storiesLength={5}
            />
          </div>
        </SwiperSlide>
      </Swiper> */}
    </Wrapper>
  );
};
export default Playground;
