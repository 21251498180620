import { useState, useEffect } from "react";
import styled from "styled-components";
import starsBgImage from "../images/galaxy_stars_bg_v2.jpg";
import { useSearchParams } from "react-router-dom";

import Header from "../components/Header";
import IntroText from "../components/hero/IntroText";
import Galaxy from "../components/Galaxy";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background-color: black;
  background-image: url(${starsBgImage});
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  position: relative;

  @media screen and (max-width: 768px) {
    overflow-y: auto;
  }
`;

const ShootingStar = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: white;
  animation: shooting 2s forwards ease;
  position: absolute;
  top: 20%;
  left: 25%;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 200px;
    height: 1px;
    background: linear-gradient(90deg, white, transparent);
  }

  @keyframes shooting {
    0% {
      transform: translate(2.5vw, 5vh) rotate(225deg);
      opacity: 1;
    }

    100% {
      transform: translate(15vw, 20vh) rotate(225deg);
      opacity: 0;
    }
  }
`;

const NewHomepage = () => {
  const [language, setLanguage] = useState(localStorage.language || "nl");
  const [searchParams, setSearchParams] = useSearchParams();
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { enlarged } = params;

  const enlargeGalaxy = () => {
    searchParams.set("enlarged", true);
    if (isMobile) {
      searchParams.set("popup", "story");
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    localStorage.language = language;
  }, [language]);

  return (
    <Wrapper>
      <Header language={language} changeLanguage={setLanguage} />
      <ShootingStar />
      <IntroText
        language={language}
        enlarged={enlarged}
        enlargeGalaxy={enlargeGalaxy}
      />
      <Galaxy language={language} enlarged={enlarged} />
    </Wrapper>
  );
};

export default NewHomepage;
