import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Video } from "../assets/icons/video.svg";
import { ReactComponent as Mic } from "../assets/icons/mic.svg";
import { ReactComponent as Upload } from "../assets/icons/upload.svg";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import copy from "../data/GalaxyCopy";

gsap.registerPlugin(MotionPathPlugin);

const PlanetComp = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-image: ${({ story }) =>
    story && story.audioSrc
      ? "linear-gradient(145deg, #d43d5d, #de8a33)"
      : "linear-gradient(145deg, #924FFF, #63BAFF)"};
  position: absolute;
  z-index: 2;
  animation: move ${({ speed }) => speed}s infinite ease-in-out;
  animation-delay: -${({ animationDelay }) => animationDelay}s;
  pointer-events: all;
  cursor: pointer;
  box-shadow: inset -9px -2px 4px rgba(218, 218, 218, 0.25),
    inset 8px 7px 5px rgba(0, 0, 0, 0.35);

  ${({ pathD }) =>
    pathD &&
    css`
      offset-path: path("${pathD}");
      offset-rotate: 0deg;
    `}
  ${({ story }) =>
    !story?.audioSrc &&
    !story?.videoSrc &&
    css`
      filter: grayscale(100%);
    `}

  &:hover {
    animation-play-state: paused;
  }

  svg {
    stroke: white;
    width: 15px;
  }

  @media screen and (max-width: 768px) {
    svg {
      display: none;
    }
  }

  @keyframes move {
    0% {
      offset-distance: 0%;
    }

    100% {
      offset-distance: 100%;
    }
  }
`;

const Tooltip = styled.span`
  width: 120px;
  background: rgba(255, 255, 255, 0.8);
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Planet = ({ showIcon = true, ...props }) => {
  const PlanetRef = useRef(null);

  useEffect(() => {
    if (
      props.pathD &&
      PlanetRef.current &&
      !CSS.supports("offset-path", `path("${props.pathD}")`)
    ) {
      const pathEl = document.querySelector(`#path-${props.ringSize}`);
      gsap.set(PlanetRef.current, {
        xPercent: -50,
        yPercent: -50,
        transformOrigin: "center center",
      });
      gsap.to(PlanetRef.current, {
        motionPath: {
          path: pathEl,
        },
        duration: props.speed,
        ease: "ease-in-out",
        repeat: -1,
        delay: -props.animationDelay,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pathD]);

  return (
    <PlanetComp ref={PlanetRef} {...props}>
      {props.tooltip && (
        <Tooltip>{copy[props.language][props.tooltip.type]}</Tooltip>
      )}
      {showIcon && (
        <>
          {props.story?.audioSrc && <Mic />}
          {props.story?.videoSrc && <Video />}
          {props.isUploadPlanet && <Upload />}
        </>
      )}
    </PlanetComp>
  );
};

export default Planet;
